import './App.css';

import { FormattedMessage, IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, PollingMode } from 'configcat-react';

import { DEFAULT_LOCALE } from '../../configuration/lang/lang';
import { isUserSessionExpired } from '../../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../../configuration/setup/hooks';
import { DisplayMessages, getDisplayMessages, getLocale } from '../../configuration/lang/langSlice';
import { DefaultRedirect } from './routes/DefaultRedirect';
import { RouteUpdater } from './routes/RouteUpdater';

import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import { AppHeader } from './header/AppHeader';
import {
    COMPOUND_MANAGEMENT_ROUTE,
    SERVICE_ORDER_ROUTE,
    SERVICE_PROVIDER_ACTIVATION_ROUTE,
    TRANSPORT_ORDER_ROUTE,
    WELCOME_ROUTE,
} from './routes/routes';
import { TransportAssignmentSidebar } from './transportAssignment/sidebar/TransportAssignmentSidebar';
import { TermsAndConditionsModal } from './activation/TermsAndConditionsModal';
import { TransportAssignments } from './transportAssignment/TransportAssignments';
import { useGetActivationStatusesQuery } from './api/activationApi';
import { Activation, ActivationStatus } from './activation/activation.types';
import React, { FunctionComponent } from 'react';
import { WelcomePage } from './activation/WelcomePage';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { getErrorMessage } from './notifications/ErrorNotification';
import { ServiceOrders } from './serviceOrders/ServiceOrders';
import { ServiceOrderSidebar } from './serviceOrders/sidebar/ServiceOrderSidebar';
import { config } from '../../config';
import { TransportCapacityOrderSidebar } from './transportAssignment/sidebar/TransportCapacityOrderSidebar';
import { ServiceProviders } from './serviceProviders/ServiceProviders';
import { CompoundManagement } from './compoundManagement/CompoundManagement';
import { VehicleInventorySidebar } from './compoundManagement/inventory/sidebar/VehicleInventorySidebar';
import { DepartedVehiclesSidebar } from './compoundManagement/departed/sidebar/DepartedVehiclesSidebar';
import { IncomingVehiclesSidebar } from './compoundManagement/incoming/sidebar/IncomingVehiclesSidebar';
import { OutgoingVehiclesSidebar } from './compoundManagement/outgoing/sidebar/OutgoingVehiclesSidebar';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

export const App = (): React.ReactElement | null => {
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);
    const { data, isLoading, isError, error } = useGetActivationStatusesQuery();

    if (!displayMessages || userLocale == null || isLoading) {
        return null;
    }

    if (isError) {
        return <AppInitError displayMessages={displayMessages} userLocale={userLocale} error={error} />;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog());
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;
    const isActiveForAnySupplier = () =>
        data !== undefined &&
        data?.filter((activation: Activation) => activation.status === ActivationStatus.ACTIVE).length > 0;

    const logger = createConsoleLogger(config.configCat.logLevel);

    const isConfigCatOffline = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test';

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ConfigCatProvider
                sdkKey={config.configCat.apiKey}
                pollingMode={PollingMode.LazyLoad}
                options={{
                    cacheTimeToLiveSeconds: config.configCat.pollingFrequencyInSeconds,
                    logger,
                    dataGovernance: DataGovernance.EuOnly,
                    offline: isConfigCatOffline,
                }}
            >
                <ApplicationLayout className="Outbound-Portal">
                    <ApplicationLayout.Header>
                        <AppHeader isActive={isActiveForAnySupplier()} />
                    </ApplicationLayout.Header>
                    <ApplicationLayout.Sidebar className="right">
                        <TransportAssignmentSidebar />
                        <TransportCapacityOrderSidebar />
                        <ServiceOrderSidebar />
                        <IncomingVehiclesSidebar />
                        <VehicleInventorySidebar />
                        <OutgoingVehiclesSidebar />
                        <DepartedVehiclesSidebar />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body>
                        <NotificationsContainer />
                        <TermsAndConditionsModal />
                        <SessionExpiredDialog
                            locale={userLocale}
                            onClose={handleSessionExpiredDialogClose}
                            show={showSessionExpired}
                        />
                        <Routes>
                            <Route path={WELCOME_ROUTE} element={<WelcomePage />} />
                            {isActiveForAnySupplier() && (
                                <>
                                    <Route path={TRANSPORT_ORDER_ROUTE} element={<TransportAssignments />} />
                                    <Route path={SERVICE_ORDER_ROUTE} element={<ServiceOrders />} />
                                    <Route path={SERVICE_PROVIDER_ACTIVATION_ROUTE} element={<ServiceProviders />} />
                                    <Route path={COMPOUND_MANAGEMENT_ROUTE} element={<CompoundManagement />} />
                                </>
                            )}
                            <Route path="*" element={<DefaultRedirect isActive={isActiveForAnySupplier()} />} />
                        </Routes>
                        <RouteUpdater />
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            </ConfigCatProvider>
        </IntlProvider>
    );
};

const AppInitError: FunctionComponent<{
    displayMessages: DisplayMessages;
    userLocale: string;
    error: FetchBaseQueryError | SerializedError;
}> = ({ displayMessages, userLocale, error }) => (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
        <ApplicationLayout className="Outbound-Portal">
            <ApplicationLayout.Body>
                <ErrorState
                    headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                    message={<FormattedMessage id={getErrorMessage(error)} />}
                />
            </ApplicationLayout.Body>
        </ApplicationLayout>
    </IntlProvider>
);
