import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import classNames from 'classnames';
import { isAfter, max } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { isEmpty, without } from 'lodash';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { usePostStatusTransportAssignmentMutation } from '../../../api/transportOrderApi';
import { handleQueryError } from '../../../notifications/ErrorNotification';
import { showSuccessNotificationAfterStatusUpdate } from '../../../notifications/SuccessNotification';
import { transportAssignmentsSlice } from '../../../reducers/transportAssignmentsSlice';
import { InputSign } from '../../../sharedComponents/InputSign';
import { isValidDate } from '../../../sharedComponents/dateHelper';
import { hasVehiclesInStatus, VehicleInExecution } from '../../TransportAssignmentUtils';
import { ExecutionStatus } from '../../transportAssignment.types';
import { getExecutionStatusDateErrorMessage } from '../confirmationForm/dateValidation';
import { ExecutionStatusDate } from './ExecutionStatusDate';
import { runInBackgroundCallback } from '../../../../../configuration/setup/backgroundActions';

const vehicleNotFinalizedOrCancelled = (vehicle: VehicleInExecution) => !vehicle.isCancelled && !vehicle.isFinalized;

// notCancelledNotFinalized > just finalized > cancelled
const sortVehicles = (a: VehicleInExecution, b: VehicleInExecution) => {
    const stateA = a.isCancelled ? 3 : a.isFinalized ? 2 : 1;
    const stateB = b.isCancelled ? 3 : b.isFinalized ? 2 : 1;
    return stateA - stateB;
};

const getLatestExecutionEventForStatus = (vehicle: VehicleInExecution, status: ExecutionStatus) => {
    const executionEventsWithMatchingStatus = vehicle.executionEvents.filter((event) => event.eventStatus === status);
    return isEmpty(executionEventsWithMatchingStatus)
        ? undefined
        : executionEventsWithMatchingStatus.reduce((prev, curr) => (isAfter(prev.eventAt, curr.eventAt) ? prev : curr));
};

type ExecutionStatusControlProps = {
    vehicles: VehicleInExecution[];
    executionPointTimezone: string;
    targetStatus: ExecutionStatus;
    isTransportAssignmentConfirmed: boolean;
    transportAssignmentReceivedAt: Date;
};

// eslint-disable-next-line max-lines-per-function
export const ExecutionStatusControl = ({
    vehicles,
    executionPointTimezone,
    targetStatus,
    isTransportAssignmentConfirmed,
    transportAssignmentReceivedAt,
}: ExecutionStatusControlProps) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const [changeTransportAssignmentStatus, statusRequest] = usePostStatusTransportAssignmentMutation();
    const [selectedTransportOrderIds, setSelectedTransportOrderIds] = useState<string[]>(
        vehicles.filter(vehicleNotFinalizedOrCancelled).map((vehicle) => vehicle.originatedTransportOrderId),
    );
    const [statusDate, setStatusDate] = useState<Date | string | undefined>();
    const [hasCheckboxError, setHasCheckboxError] = useState<boolean>(false);
    const [dateErrorMessage, setDateErrorMessage] = useState<string | undefined>(undefined);

    const hasDateError = dateErrorMessage !== undefined;
    const hasLoadedVehicles = hasVehiclesInStatus(vehicles, ExecutionStatus.LOADED);
    const hasUnloadedVehicles = hasVehiclesInStatus(vehicles, ExecutionStatus.UNLOADED);
    const sortedVehicles = [...vehicles].sort(sortVehicles);

    const closeSidebar = () => {
        dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
    };

    const onVehicleCheckboxClickHandler = (id: string) => {
        const updatedSelectedVehicleIds = selectedTransportOrderIds.includes(id)
            ? without(selectedTransportOrderIds, id)
            : [...selectedTransportOrderIds, id];

        setSelectedTransportOrderIds(updatedSelectedVehicleIds);
        setHasCheckboxError(false);
    };

    const checkForm = () => {
        const loadingDates = vehicles
            .filter(
                (vehicle) =>
                    selectedTransportOrderIds.includes(vehicle.originatedTransportOrderId) &&
                    vehicle.executionEvents.some((event) => event.eventStatus === ExecutionStatus.LOADED),
            )
            .map((vehicle) => getLatestExecutionEventForStatus(vehicle, ExecutionStatus.LOADED)!!.eventAt);

        const newestLoadingDate =
            isEmpty(loadingDates) || targetStatus === ExecutionStatus.LOADED ? undefined : max(loadingDates);

        const dateErrorMessageId = getExecutionStatusDateErrorMessage(
            statusDate,
            transportAssignmentReceivedAt,
            newestLoadingDate,
        );
        const noSelectedCheckboxes = selectedTransportOrderIds.length === 0;
        setDateErrorMessage(dateErrorMessageId);
        setHasCheckboxError(noSelectedCheckboxes);
        const hasError = dateErrorMessageId !== undefined || noSelectedCheckboxes;
        return !hasError;
    };

    const onSubmitButtonClickHandler = runInBackgroundCallback(async () => {
        const isFormValid = checkForm();
        if (!isFormValid) {
            return;
        }
        const statusChange = {
            executionStatusChanged: {
                transportOrderIds: selectedTransportOrderIds,
                vehicleIds: selectedTransportOrderIds.map(
                    (vehicleId) => vehicles.find((vehicle) => vehicle.originatedTransportOrderId === vehicleId)!.id,
                ),
                status: targetStatus,
                changedAt: new Date(),
                statusAt: statusDate!! as Date,
            },
        };
        await changeTransportAssignmentStatus(statusChange)
            .unwrap()
            .then(() => {
                showSuccessNotificationAfterStatusUpdate(targetStatus);
                closeSidebar();
            })
            .catch(handleQueryError);
    });

    const onDateChangeHandler = (timezone: string, newStatusDate: string | Moment) => {
        const statusDateOrString = moment.isMoment(newStatusDate)
            ? fromZonedTime(newStatusDate.toDate(), timezone)
            : newStatusDate;
        setStatusDate(statusDateOrString);
        setDateErrorMessage(undefined);
    };

    const shouldLoadingBePrimaryButton =
        targetStatus === ExecutionStatus.LOADED &&
        isTransportAssignmentConfirmed &&
        !hasLoadedVehicles &&
        !hasUnloadedVehicles;

    const shouldUnloadingBePrimaryButton =
        targetStatus === ExecutionStatus.UNLOADED && hasLoadedVehicles && !hasUnloadedVehicles;

    const shouldBePrimaryButton = shouldLoadingBePrimaryButton || shouldUnloadingBePrimaryButton;

    const hasVehiclesInTargetStatus =
        (targetStatus === ExecutionStatus.LOADED && hasLoadedVehicles) ||
        (targetStatus === ExecutionStatus.UNLOADED && hasUnloadedVehicles);

    const isFormDisabled = vehicles.every((vehicle) => vehicle.isCancelled || vehicle.isFinalized);

    const getActionButtonProperties = (): [icon: string, translationId: string] => {
        if (hasVehiclesInTargetStatus) {
            return ['rioglyph-time', 'outboundPortal.transportAssignments.sidebar.executionStatus.updateStatus'];
        }
        switch (targetStatus) {
            case ExecutionStatus.LOADED:
                return ['rioglyph-truck', 'outboundPortal.transportAssignments.sidebar.executionStatus.confirmLoading'];
            case ExecutionStatus.UNLOADED:
                return [
                    'rioglyph-finish',
                    'outboundPortal.transportAssignments.sidebar.executionStatus.confirmUnloading',
                ];
        }
    };

    return (
        <>
            <div
                className={classNames('margin-top-15 form-group', {
                    'has-feedback has-error margin-bottom-25': hasCheckboxError,
                })}
            >
                {sortedVehicles.map((vehicle) => {
                    const isDisabled = vehicle.isCancelled || vehicle.isFinalized;
                    const executionEvent = getLatestExecutionEventForStatus(vehicle, targetStatus);
                    return (
                        <div key={`${vehicle.id.vin}-${vehicle.id.productionNumber}`}>
                            <Checkbox
                                disabled={isDisabled}
                                checked={selectedTransportOrderIds.includes(vehicle.originatedTransportOrderId)}
                                onClick={() => onVehicleCheckboxClickHandler(vehicle.originatedTransportOrderId)}
                                className="white-space-nowrap"
                                error={hasCheckboxError && !isDisabled}
                            >
                                <span
                                    className="rioglyph rioglyph-car margin-right-5 pull-left margin-top-4"
                                    aria-hidden="true"
                                />
                                <span
                                    title={vehicle.model.name}
                                    className={classNames(
                                        'pull-left display-block overflow-hidden ellipsis-1 width-30pct',
                                        {
                                            'text-decoration-line-through': vehicle.isCancelled,
                                        },
                                    )}
                                >
                                    {vehicle.model.name}
                                </span>
                                <span
                                    className={classNames('padding-right-10 padding-left-10 pull-left', {
                                        'text-decoration-line-through': vehicle.isCancelled,
                                    })}
                                >
                                    {vehicle.id.vin !== undefined && vehicle.id.vin}
                                    {vehicle.id.vin === undefined && vehicle.id.productionNumber}
                                </span>
                                <ExecutionStatusDate
                                    status={targetStatus}
                                    hasActiveIncident={vehicle.incident?.isActive ?? false}
                                    eventType={executionEvent?.eventType}
                                    value={executionEvent?.eventAt}
                                    timeZone={executionPointTimezone}
                                />
                            </Checkbox>
                        </div>
                    );
                })}
                {hasCheckboxError && (
                    <span className="help-block left-0">
                        <FormattedMessage id="outboundPortal.transportAssignments.sidebar.executionStatus.noSelectedVehicle" />
                    </span>
                )}
            </div>
            <div className={classNames('display-flex align-items-start')}>
                <div
                    className={classNames('margin-right-10 flex-1-0 min-width-100', {
                        'form-group has-feedback margin-bottom-25 has-error': hasDateError,
                    })}
                >
                    <DatePicker
                        clearableInput
                        mandatory={false}
                        locale={intl.locale}
                        alignRight={false}
                        inputProps={{
                            placeholder: intl.formatMessage({
                                id: 'outboundPortal.common.chooseDatePlaceholder',
                            }),
                            disabled: isFormDisabled,
                        }}
                        value={isValidDate(statusDate) ? toZonedTime(statusDate, executionPointTimezone) : statusDate}
                        onChange={(value: Moment | string) => {
                            onDateChangeHandler(executionPointTimezone, value);
                        }}
                        className={classNames({ 'has-error margin-bottom-0': hasDateError })}
                    />
                    {hasDateError && (
                        <div className={classNames('margin-right-20')}>
                            <InputSign messageId={dateErrorMessage} glyph="rioglyph-error-sign" />
                        </div>
                    )}
                </div>
                <div>
                    <button
                        type="button"
                        className={classNames('btn', 'btn-default', { 'btn-primary': shouldBePrimaryButton })}
                        onClick={onSubmitButtonClickHandler}
                        disabled={statusRequest.isLoading || isFormDisabled}
                    >
                        <span className={classNames('rioglyph', getActionButtonProperties()[0])} aria-hidden="true" />
                        <FormattedMessage id={getActionButtonProperties()[1]} />
                    </button>
                </div>
            </div>
        </>
    );
};
