import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { useGetDepartedVehiclesQuery } from '../../api/compoundManagementApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import {
    getCompoundManagementModalFilter,
    getDepartedVehiclesSorting,
    getVinSearchFilter,
} from '../../reducers/compoundManagementSlice';
import { LoadMoreButton } from '../../sharedComponents/table/LoadMoreButton';
import { useSetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { DepartedVehiclesTable } from './table/DepartedVehiclesTable';
import { CompoundManagementToolbar } from '../toolbar/CompoundManagementToolbar';

export const DEFAULT_COMPOUND_DEPARTED_VEHICLES_LIMIT_SIZE = 100;
const POLLING_INTERVAL = 60000;

export const DepartedVehicles: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const filters = useAppSelector(getCompoundManagementModalFilter);
    const vinSearch = useAppSelector(getVinSearchFilter);
    const sorting = useAppSelector(getDepartedVehiclesSorting);

    const [limit, setLimit] = useState(DEFAULT_COMPOUND_DEPARTED_VEHICLES_LIMIT_SIZE);

    const {
        data: departedVehiclesPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetDepartedVehiclesQuery(
        {
            locationId: filters.locationIdentifierFilter,
            vehicleType: filters.vehicleTypeFilter,
            manufacturer: filters.manufacturerFilter,
            arrivalDateStartAt: filters.arrivalDateStartAtFilter,
            arrivalDateEndAt: filters.arrivalDateEndAtFilter,
            departureDateStartAt: filters.departureDateStartAtFilter,
            departureDateEndAt: filters.departureDateEndAtFilter,
            vin: vinSearch.length === 0 ? undefined : vinSearch,
            limit,
            sortBy: sorting,
        },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [],
    });

    if (isError) {
        console.error(error);
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (departedVehiclesPage === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    const departedVehicles = departedVehiclesPage.items;
    return (
        <>
            <CompoundManagementToolbar />
            <DepartedVehiclesTable vehicles={departedVehicles} />
            {departedVehicles.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_DEPARTED_VEHICLES_LIMIT_SIZE)}
                    hasMoreToLoad={departedVehiclesPage.hasMore}
                />
            )}
        </>
    );
};
