import { FunctionComponent } from 'react';
import { ServiceProvidersTable } from './ServiceProvidersTable';
import { useGetServiceProvidersQuery } from '../api/serviceProviderApi';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { handleQueryError } from '../notifications/ErrorNotification';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import { FormattedMessage } from 'react-intl';
import { ServiceProviderCounts } from './ServiceProviderCounts';

export const ServiceProviders: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);

    const {
        data: serviceProvidersPage,
        isLoading,
        isError,
        error,
    } = useGetServiceProvidersQuery(undefined, {
        pollingInterval: isSessionExpired ? undefined : 60000,
    });

    if (isError) {
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (serviceProvidersPage === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    return (
        <>
            <ServiceProviderCounts serviceProviders={serviceProvidersPage.serviceProviders} />
            <ServiceProvidersTable
                serviceProviders={serviceProvidersPage.serviceProviders}
                allowedActions={serviceProvidersPage.allowedActions}
            />
        </>
    );
};
