import { formatDateTimeInTimeZone } from '../../../sharedComponents/dateHelper';

type DateTimeInfoProps = {
    date: Date;
    timezone?: string;
};

export const DateTimeInfo = ({ date, timezone }: DateTimeInfoProps) => (
    <span>{formatDateTimeInTimeZone(date, timezone ? timezone : 'UTC')}</span>
);
