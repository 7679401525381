import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import messagesEN from '../../features/translations/en-GB.json';

export class ErrorBoundary extends React.Component<{ children?: ReactNode }, { hasError: boolean }> {
    constructor(props: { children?: ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtra('componentStack', errorInfo.componentStack);
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <ApplicationLayout className="Outbound-Portal">
                    <ApplicationLayout.Body>
                        <ErrorState
                            headline={messagesEN['intl-msg:common-message.error.generic.headline']}
                            message={messagesEN['intl-msg:common-message.error.generic.message']}
                        />
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            );
        }

        return this.props.children;
    }
}
