import TableToolbar from '@rio-cloud/rio-uikit/TableToolbar';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { compoundManagementSlice, getActiveCompoundManagementView } from '../../reducers/compoundManagementSlice';
import { getTableViewType, viewSlice } from '../../reducers/viewSlice';
import { CompoundManagementViewSelection } from '../toolbar/CompoundManagementViewSelection';
import { CompoundManagementView } from '../compoundManagement.types';
import { TABLE_VIEW_BUTTONS_TEST_ID } from '../../sharedComponents/commonConfig';
import { CompoundManagementVinSearch } from '../toolbar/CompoundManagementVinSearch';
import { CompoundManagementFilterModal } from '../toolbar/CompoundManagementFilterModal';

export const CompoundManagementToolbar: FunctionComponent = () => {
    const viewType = useAppSelector(getTableViewType);
    const view = useAppSelector(getActiveCompoundManagementView);
    const dispatch = useAppDispatch();

    const handleSwitchView = (newView: CompoundManagementView) => {
        dispatch(compoundManagementSlice.actions.switchView(newView));
    };

    const handleViewTypeChange = (newViewType: TableViewTogglesViewType) =>
        dispatch(viewSlice.actions.setTableViewType(newViewType));

    return (
        <TableToolbar>
            <div className="table-toolbar-container">
                <div className="table-toolbar-group-left">
                    <div className="table-toolbar-column">
                        <div className="btn-toolbar table-btn-toolbar">
                            <CompoundManagementViewSelection activeView={view} setActiveView={handleSwitchView} />
                        </div>
                    </div>
                </div>
                <div className="table-toolbar-group-right">
                    <div className="table-toolbar-column">
                        <CompoundManagementFilterModal modalType={view} />
                    </div>
                    <div className="table-toolbar-column">
                        <CompoundManagementVinSearch />
                    </div>
                    <div className="table-toolbar-column">
                        <TableViewToggles
                            data-testid={TABLE_VIEW_BUTTONS_TEST_ID}
                            initialViewType={viewType}
                            onViewTypeChange={handleViewTypeChange}
                        />
                    </div>
                </div>
            </div>
        </TableToolbar>
    );
};
