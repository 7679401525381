import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export const CancelledArrivalDetailsHeader: FunctionComponent<{ isWarning?: boolean | undefined }> = ({
    isWarning = false,
}) => {
    const [color, sign] = isWarning
        ? ['text-color-warning', 'rioglyph-info-sign']
        : ['text-color-danger', 'rioglyph-error-sign'];

    return (
        <span className={color}>
            <span className={`rioglyph ${sign} text-size-18 padding-right-5`} />
            <FormattedMessage id="outboundOrderBook.compoundManagement.sidebar.details.arrivalCancelled" />
        </span>
    );
};
