import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import { FormattedMessage } from 'react-intl';
import { NoValue } from '../../../../sharedComponents/NoValue';
import { Address } from '../../../../sharedComponents/common.types';
import {
    TransportAssignment,
    TransportAssignmentStatus,
    TransportAssignmentType,
} from '../../../transportAssignment.types';
import { ShipmentDetailsExpander } from './ShipmentDetailsExpander';
import { TransportAssignmentsDelivery } from './shipmentDetails.types';

type ShipmentDetailAddressProps = {
    readonly address?: Address;
};

export const ShipmentDetailAddress = ({ address }: ShipmentDetailAddressProps) => {
    const hasName = address?.name != null;
    const addressDetails = [address?.street, address?.postalCode, address?.city, address?.countryCode].filter(
        (value) => value,
    ) as string[];

    const addressToolTipContent = [
        address?.name,
        address?.street,
        address?.postalCode,
        address?.city,
        address?.countryCode,
    ].filter((value) => value) as string[];

    return (
        <div className="bg-lightest border border-bottom-none display-flex rounded padding-15">
            <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.details.address.to" />
            {!address ? (
                <NoValue />
            ) : (
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip" width="auto" style={{ maxWidth: 370 }}>
                            {addressToolTipContent.join(', ')}
                        </Tooltip>
                    }
                >
                    <div className="ellipsis-1">
                        <span className="text-size-20 margin-left-10 rioglyph rioglyph-map-marker" />

                        {hasName && <span className="text-medium">{address.name}</span>}
                        {hasName && addressDetails.length > 0 && ', '}
                        {addressDetails.join(', ')}
                    </div>
                </OverlayTrigger>
            )}
        </div>
    );
};

type TransportAssignmentDetailProps = {
    readonly address?: Address;
    readonly deliveries: TransportAssignmentsDelivery[];
    readonly openFirst: boolean;
};

const TransportAssignmentDetail = ({ address, deliveries, openFirst }: TransportAssignmentDetailProps) => {
    return (
        <>
            <ShipmentDetailAddress address={address} />
            <ShipmentDetailsExpander deliveries={deliveries} openFirst={openFirst} />
        </>
    );
};

type TransportAssignmentShipments = {
    readonly address?: Address;
    readonly deliveries: TransportAssignmentsDelivery[];
};

const getShipmentDetailInformation = (transportAssignment: TransportAssignment): TransportAssignmentShipments[] => {
    switch (transportAssignment.type) {
        case TransportAssignmentType.TRANSPORT_ORDER:
            return [
                {
                    address: transportAssignment.unloading.addressAndContact?.address,
                    deliveries: [
                        {
                            vehicle: transportAssignment.vehicle,
                            serviceKey: transportAssignment.serviceKey,
                            generalInformation: transportAssignment.generalInformation,
                            isCancelled: transportAssignment.status === TransportAssignmentStatus.CANCELLATION,
                        },
                    ],
                },
            ];
        case TransportAssignmentType.TRANSPORT_ORDER_BUNDLE:
            return transportAssignment.deliveries.map(({ unloading, originatedTransportOrders }) => ({
                address: unloading.addressAndContact?.address,
                deliveries: originatedTransportOrders.flatMap((originatedTransportOrder) => ({
                    vehicle: originatedTransportOrder.vehicle,
                    serviceKey: originatedTransportOrder.serviceKey,
                    generalInformation: originatedTransportOrder.generalInformation,
                    isCancelled: originatedTransportOrder.isCancelled,
                })),
            }));
        default:
            return [];
    }
};

type ShipmentDetailsProps = {
    readonly transportAssignment: TransportAssignment;
};

export const ShipmentDetails = ({ transportAssignment }: ShipmentDetailsProps) => {
    const shipmentDetails = getShipmentDetailInformation(transportAssignment);

    return (
        <>
            {shipmentDetails.map(({ address, deliveries }, index) => (
                <TransportAssignmentDetail
                    key={index}
                    address={address}
                    deliveries={deliveries}
                    openFirst={shipmentDetails.length === 1 && deliveries.length === 1}
                />
            ))}
        </>
    );
};
