import ExpanderList, { ExpanderListItem } from '@rio-cloud/rio-uikit/ExpanderList';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import {
    compoundManagementSlice,
    getActiveCompoundManagementView,
    getSelectedCompoundVehicle,
} from '../../../reducers/compoundManagementSlice';
import { CompoundManagementView, OutgoingVehicle } from '../../compoundManagement.types';
import { CancelledDepartureDetailsHeader } from '../../sidebar/CancelledDepartureDetailsHeader';
import { CompoundLocationAndDestinationDetails } from '../../sidebar/CompoundLocationAndDestinationDetails';
import { CompoundVehicleVIN } from '../../sidebar/CompoundVehicleVIN';
import { DepartureDetailsHeader } from '../../sidebar/DepartureDetailsHeader';
import { VehicleDetails } from '../../sidebar/VehicleDetails';
import { ExpectedDepartureDetails, ExpectedDepartureDetailsProps } from './ExpectedDepartureDetails';

type OutgoingSidebarInternalProps = {
    selectedVehicle: OutgoingVehicle | undefined;
    closeSidebar: () => void;
};

export const OutgoingVehiclesSidebar: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const selectedVehicle = useAppSelector(getSelectedCompoundVehicle);
    const activeManagementView = useAppSelector(getActiveCompoundManagementView);

    const closeSidebar = () => {
        dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
    };

    if (activeManagementView === CompoundManagementView.OUTGOING_VEHICLES) {
        return (
            <OutgoingVehiclesSidebarInternal
                key={selectedVehicle?.id}
                selectedVehicle={selectedVehicle as OutgoingVehicle | undefined}
                closeSidebar={closeSidebar}
            />
        );
    }
};

const OutgoingVehiclesSidebarInternal: FunctionComponent<OutgoingSidebarInternalProps> = ({
    selectedVehicle,
    closeSidebar,
}) => {
    const sidebarProperties = {
        showHeaderBorder: true,
        resizable: true,
        position: Sidebar.RIGHT,
        minWidth: 400,
        maxWidth: 550,
        width: 550,
        fly: true,
    };

    if (selectedVehicle !== undefined) {
        const title = <FormattedMessage id="outboundOrderBook.compoundManagement.common.sidebar.header.titleVehicle" />;

        const footerButton = (
            <div className="display-flex gap-10 flex-row-reverse">
                <button className="btn btn-default" type="button" onClick={closeSidebar}>
                    <FormattedMessage id="outboundOrderBook.common.button.close" />
                </button>
            </div>
        );

        return (
            <Sidebar
                {...sidebarProperties}
                closed={false}
                onClose={closeSidebar}
                title={title}
                titleClassName="padding-left-10"
                footer={footerButton}
                footerClassName="margin-right-10"
            >
                <CompoundVehicleVIN vehicle={selectedVehicle.vehicle} />
                <CompoundLocationAndDestinationDetails
                    compoundLocation={selectedVehicle.compoundLocation}
                    vehicle={selectedVehicle.vehicle}
                />
                <ExpanderList
                    items={[
                        buildExpectedDepartureDetailsItem({
                            compoundVehicleId: selectedVehicle.id,
                            compoundLocation: selectedVehicle.compoundLocation,
                            departure: selectedVehicle.departure,
                        }),
                    ]}
                    className="margin-20"
                    unmountOnExit={false}
                />
                <VehicleDetails vehicle={selectedVehicle.vehicle} isCancelled={false} />
            </Sidebar>
        );
    } else {
        return <Sidebar {...sidebarProperties} closed />;
    }
};

const buildExpectedDepartureDetailsItem = (props: ExpectedDepartureDetailsProps): ExpanderListItem => {
    const isCancelled = props.departure.isCancelled;

    return {
        id: 'departure',
        open: true,
        header: isCancelled ? <CancelledDepartureDetailsHeader /> : <DepartureDetailsHeader inputRequired />,
        body: <ExpectedDepartureDetails {...props} />,
        bodyClassName: 'border-top-only border-width-1 border-style-solid border-color-light',
        className: isCancelled ? 'list-group-item-danger' : 'list-group-item-primary',
    };
};
