import DatePicker from '@rio-cloud/rio-uikit/DatePicker';
import classNames from 'classnames';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import moment, { Moment } from 'moment';
import { FunctionComponent, useId } from 'react';
import { useIntl } from 'react-intl';
import { isValidDate } from '../../../sharedComponents/dateHelper';
import { InputSign } from '../../../sharedComponents/InputSign';
import { LabelWithTimezone } from '../../../sharedComponents/LabelWithTimezone';

type CompletedStatusAtInputProps = {
    labelId: string;
    disabled: boolean;
    timeZone: string;
    statusDate: Date | string | undefined;
    onChangeStatusDate: (value: Date | string | undefined) => void;
    validationErrorId: string | undefined;
};

export const CompletedStatusAtInput: FunctionComponent<CompletedStatusAtInputProps> = ({
    labelId,
    disabled,
    timeZone,
    statusDate,
    onChangeStatusDate,
    validationErrorId,
}) => {
    const intl = useIntl();
    const inputId = useId();

    const hasDateError = validationErrorId !== undefined;
    const placeHolderMessageId = disabled
        ? 'outboundOrderBook.common.noData'
        : 'outboundPortal.common.chooseDatePlaceholder';
    return (
        <div
            className={classNames('flex-1-1 width-50pct min-width-100', {
                'form-group has-feedback margin-bottom-25 has-error': hasDateError,
            })}
        >
            <LabelWithTimezone htmlFor={inputId} timeZoneData={{ timeZone, date: new Date() }} messageId={labelId} />
            <DatePicker
                id={inputId}
                clearableInput
                mandatory={false}
                locale={intl.locale}
                alignRight={false}
                inputProps={{
                    placeholder: intl.formatMessage({
                        id: placeHolderMessageId,
                    }),
                    disabled,
                }}
                value={isValidDate(statusDate) ? toZonedTime(statusDate, timeZone) : statusDate}
                onChange={(value: Moment | string) => {
                    const statusDateOrString = moment.isMoment(value) ? fromZonedTime(value.toDate(), timeZone) : value;
                    onChangeStatusDate(statusDateOrString);
                }}
                className={classNames({ 'has-error margin-bottom-0': hasDateError })}
            />
            {hasDateError && (
                <div>
                    <InputSign offsetForLabel messageId={validationErrorId} glyph="rioglyph-error-sign" />
                </div>
            )}
        </div>
    );
};
