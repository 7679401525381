import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { getByDefaultHierarchy } from '../TransportAssignmentUtils';
import { getTransportAssignmentSidebarExpanderStylesAndSettings } from '../expanderStyleAndSettings';
import { TransportAssignment, TransportAssignmentType } from '../transportAssignment.types';

const getPickUpDetailsExpanderLabelId = (transportAssignment: TransportAssignment): string => {
    return getByDefaultHierarchy(
        transportAssignment.status,
        transportAssignment.isArchived,
        transportAssignment.isFinalized,
        {
            archived: 'outboundPortal.transportAssignments.sidebar.expanderTitle.archived',
            finalized: 'outboundPortal.transportAssignments.sidebar.expanderTitle.finalized',
            unconfirmed:
                transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
                    ? 'outboundPortal.transportAssignments.sidebar.expanderTitle.unconfirmedCapacity'
                    : 'outboundPortal.transportAssignments.sidebar.expanderTitle.unconfirmed',
            incompleteConfirmed:
                transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
                    ? 'outboundPortal.transportAssignments.sidebar.expanderTitle.incompleteDataForCapacity'
                    : 'outboundPortal.transportAssignments.sidebar.expanderTitle.incompleteData',
            completeConfirmed:
                transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
                    ? 'outboundPortal.transportAssignments.sidebar.expanderTitle.completeDataForCapacity'
                    : 'outboundPortal.transportAssignments.sidebar.expanderTitle.completeData',
            loaded: 'outboundPortal.transportAssignments.sidebar.expanderTitle.completeData',
            unloaded: 'outboundPortal.transportAssignments.sidebar.expanderTitle.completeData',
            cancelled:
                transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER
                    ? 'outboundPortal.transportAssignments.sidebar.expanderTitle.cancelledCapacity'
                    : 'outboundPortal.transportAssignments.sidebar.expanderTitle.cancelledOrder',
        },
    );
};

type TransportAssignmentPickUpDetailsProps = PropsWithChildren<{
    transportAssignment: TransportAssignment;
}>;

export const SidebarExpander = ({ transportAssignment, children }: TransportAssignmentPickUpDetailsProps) => {
    const expanderLabelId = getPickUpDetailsExpanderLabelId(transportAssignment);

    const { bodyBackgroundStyles, headerTextStyles, titleIcon, isOpen, borderStyle } =
        getTransportAssignmentSidebarExpanderStylesAndSettings(transportAssignment);

    return (
        <div className={classNames(borderStyle, 'border-style-solid', 'border-width-1', 'border-bottom-only')}>
            <ExpanderPanel
                title={
                    <>
                        <div className={classNames(headerTextStyles)}>
                            {titleIcon && <span className={classNames(titleIcon)} />}
                            <FormattedMessage id={expanderLabelId} />
                        </div>
                        <div className="separator margin-left-10 margin-right-5">
                            <hr className="margin-bottom-0 margin-top-10" />
                        </div>
                    </>
                }
                bsStyle="blank"
                className={classNames(
                    ...bodyBackgroundStyles,
                    'padding-left-20 padding-right-20 padding-top-10 padding-bottom-10 margin-bottom-0 panel-separator',
                )}
                open={isOpen}
                titleClassName="text-medium text-primary margin-left-0 display-flex flex-1-1"
            >
                {children}
            </ExpanderPanel>
        </div>
    );
};
