import { ColumnDefinition } from '../../../sharedComponents/table/SortedTableHeader';

export enum IncomingVehiclesTableColumn {
    VIN = 'vin',
    LOCATION_ID = 'locationId',
    LOCATION_CITY = 'locationCity',
    EXPECTED_ARRIVAL_DATE = 'expectedArrivalDate',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    DISPATCH_TYPE = 'dispatchType',
    FUEL_TYPE = 'fuelType',
    MODEL_VERSION = 'modelVersion',
    ARRIVAL_FREIGHT_FORWARDER = 'arrivalFreightForwarder',
    MEANS_OF_TRANSPORT_TYPE = 'meansOfTransportType',
    MEANS_OF_TRANSPORT = 'meansOfTransport',
    ACTION = 'action',
}

export const incomingVehiclesColumnDefinition: ColumnDefinition<IncomingVehiclesTableColumn>[] = [
    {
        column: IncomingVehiclesTableColumn.VIN,
        labelId: 'outboundOrderBook.common.heading.vin',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.LOCATION_ID,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.locationId',
        sortable: true,
    },
    {
        column: IncomingVehiclesTableColumn.LOCATION_CITY,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.location',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.EXPECTED_ARRIVAL_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.expectedArrivalDate',
        sortable: true,
    },
    {
        column: IncomingVehiclesTableColumn.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.model',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.DISPATCH_TYPE,
        labelId: 'outboundOrderBook.common.heading.vehicleType',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.FUEL_TYPE,
        labelId: 'outboundOrderBook.common.heading.fuelType',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.MODEL_VERSION,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.modelVersion',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.carrier',
        sortable: true,
    },
    {
        column: IncomingVehiclesTableColumn.MEANS_OF_TRANSPORT_TYPE,
        labelId: 'outboundOrderBook.common.heading.meansOfTransportType',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.MEANS_OF_TRANSPORT,
        labelId: 'outboundOrderBook.common.heading.meansOfTransport',
        sortable: false,
    },
    {
        column: IncomingVehiclesTableColumn.ACTION,
        labelId: 'outboundPortal.compoundManagement.table.action',
        sortable: false,
    },
];
