import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { compoundManagementSlice, getVehicleInventorySorting } from '../../../reducers/compoundManagementSlice';
import { AppTableCardsSorting } from '../../../sharedComponents/table/AppTableCardsSorting';
import { TableSort } from '../../../sharedComponents/table/SortedTableHeader';
import { InventoryVehicle } from '../../compoundManagement.types';
import { Table } from '../../shared/table/Table';
import { CompoundManagementTableRow } from '../../table/CompoundManagementTableRow';
import { vehicleInventoryColumnDefinition, VehicleInventoryTableColumn } from './VehicleInventoryTableColumn';
import { VehicleInventoryTableDataContent } from './VehicleInventoryTableDataContent';

type VehicleInventoryTableProps = {
    readonly vehicles: readonly InventoryVehicle[];
};

export const VehicleInventoryTable = ({ vehicles }: VehicleInventoryTableProps) => {
    const sorting = useAppSelector(getVehicleInventorySorting);
    const dispatch = useAppDispatch();

    const setSorting = (newSorting: TableSort<VehicleInventoryTableColumn>) =>
        dispatch(compoundManagementSlice.actions.setInventorySorting(newSorting));

    return (
        <>
            <AppTableCardsSorting
                columnDefinition={vehicleInventoryColumnDefinition}
                sorting={sorting}
                setSorting={setSorting}
            />
            <Table
                columnDefinition={vehicleInventoryColumnDefinition}
                sorting={sorting}
                setSorting={setSorting}
                items={vehicles}
                notFoundMessage={
                    <FormattedMessage id="outboundOrderBook.compoundManagement.vehicleInventory.notFound" />
                }
            >
                {vehicles.map((vehicle: InventoryVehicle) => (
                    <CompoundManagementTableRow
                        key={vehicle.id}
                        highlight={vehicle.arrival.isCancelled ? 'danger' : undefined}
                        columnDefinition={vehicleInventoryColumnDefinition}
                        vehicle={vehicle}
                    >
                        {(column) => <VehicleInventoryTableDataContent column={column} vehicle={vehicle} />}
                    </CompoundManagementTableRow>
                ))}
            </Table>
        </>
    );
};
