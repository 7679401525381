import { ColumnDefinition } from '../../../sharedComponents/table/SortedTableHeader';

export enum VehicleInventoryTableColumn {
    VIN = 'vin',
    LOCATION_ID = 'locationId',
    LOCATION_CITY = 'locationCity',
    MANUFACTURER = 'manufacturer',
    MODEL = 'model',
    DISPATCH_TYPE = 'dispatchType',
    FUEL_TYPE = 'fuelType',
    MODEL_VERSION = 'modelVersion',
    ARRIVAL_DATE = 'arrivalDate',
    ARRIVAL_FREIGHT_FORWARDER = 'arrivalFreightForwarder',
}

export const vehicleInventoryColumnDefinition: ColumnDefinition<VehicleInventoryTableColumn>[] = [
    {
        column: VehicleInventoryTableColumn.VIN,
        labelId: 'outboundOrderBook.common.heading.vin',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.LOCATION_ID,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.locationId',
        sortable: true,
    },
    {
        column: VehicleInventoryTableColumn.LOCATION_CITY,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.location',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.MANUFACTURER,
        labelId: 'outboundOrderBook.common.heading.manufacturer',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.MODEL,
        labelId: 'outboundOrderBook.common.heading.model',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.DISPATCH_TYPE,
        labelId: 'outboundOrderBook.common.heading.vehicleType',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.FUEL_TYPE,
        labelId: 'outboundOrderBook.common.heading.fuelType',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.MODEL_VERSION,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.modelVersion',
        sortable: false,
    },
    {
        column: VehicleInventoryTableColumn.ARRIVAL_DATE,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.arrivalDate',
        sortable: true,
    },
    {
        column: VehicleInventoryTableColumn.ARRIVAL_FREIGHT_FORWARDER,
        labelId: 'outboundOrderBook.compoundManagement.table.heading.arrivalCarrier',
        sortable: true,
    },
];
