import { FunctionComponent } from 'react';
import { assertExhaustive } from '../../../../../utils';
import { NoValue } from '../../../sharedComponents/NoValue';
import { IncomingVehicle } from '../../compoundManagement.types';
import { DisplayDispatchType } from '../../shared/dataDisplay/DisplayDispatchType';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayFuelType } from '../../shared/dataDisplay/DisplayFuelType';
import { DisplayMeansOfTransportType } from '../../shared/dataDisplay/DisplayMeansOfTransportType';
import { DisplayModelName } from '../../shared/dataDisplay/DisplayModelName';
import { IncomingVehiclesTableColumn } from './IncomingVehiclesTableColumn';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DateTime } from '../../../sharedComponents/table/BaseTableComponents';
import { CompoundVehicleActionButton } from '../../table/CompoundVehicleActionButton';

type TableDataContentProps = { column: IncomingVehiclesTableColumn; vehicle: IncomingVehicle };

export const IncomingVehiclesTableDataContent: FunctionComponent<TableDataContentProps> = ({ column, vehicle }) => {
    const isCancelled = vehicle.arrival.isCancelled;

    switch (column) {
        case IncomingVehiclesTableColumn.VIN:
            return <div className="ellipsis-1">{vehicle.vehicle.vin}</div>;
        case IncomingVehiclesTableColumn.LOCATION_ID:
            return <span>{vehicle.compoundLocation.identifier}</span>;
        case IncomingVehiclesTableColumn.LOCATION_CITY:
            return <span>{vehicle.compoundLocation.address.city}</span>;
        case IncomingVehiclesTableColumn.EXPECTED_ARRIVAL_DATE:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DateTime
                        dateTime={vehicle.arrival.expectedArrivalAt}
                        timeZone={vehicle.compoundLocation.timeZone}
                    />
                </DisplayCancellableData>
            );
        case IncomingVehiclesTableColumn.MANUFACTURER:
            return vehicle.vehicle.model.manufacturer ?? <NoValue />;
        case IncomingVehiclesTableColumn.MODEL:
            return <DisplayModelName vehicle={vehicle.vehicle} />;
        case IncomingVehiclesTableColumn.DISPATCH_TYPE:
            return <DisplayDispatchType vehicle={vehicle.vehicle} />;
        case IncomingVehiclesTableColumn.FUEL_TYPE:
            return <DisplayFuelType vehicle={vehicle.vehicle} />;
        case IncomingVehiclesTableColumn.MODEL_VERSION:
            return vehicle.vehicle.model.version ?? <NoValue />;
        case IncomingVehiclesTableColumn.ARRIVAL_FREIGHT_FORWARDER:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DisplayFreightForwarder
                        className="ellipsis-1"
                        freightForwarder={vehicle.arrival.freightForwarder}
                    />
                </DisplayCancellableData>
            );
        case IncomingVehiclesTableColumn.MEANS_OF_TRANSPORT:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    {vehicle.arrival.meansOfTransport.id ?? <NoValue />}
                </DisplayCancellableData>
            );
        case IncomingVehiclesTableColumn.MEANS_OF_TRANSPORT_TYPE:
            return (
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DisplayMeansOfTransportType meansOfTransportType={vehicle.arrival.meansOfTransport.type} />
                </DisplayCancellableData>
            );
        case IncomingVehiclesTableColumn.ACTION:
            return (
                isCancelled || (
                    <CompoundVehicleActionButton
                        labelId="outboundPortal.compoundManagement.button.confirmArrival"
                        icon="rioglyph-load"
                        vehicle={vehicle}
                    />
                )
            );
        default:
            assertExhaustive(column);
    }
};
