import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

export const DepartureDetailsHeader: FunctionComponent<{ inputRequired?: boolean | undefined }> = ({
    inputRequired = false,
}) => {
    return (
        <span className={inputRequired ? 'text-color-primary' : 'text-color-default'}>
            <span className="rioglyph rioglyph-load-unload text-size-18 padding-right-5" />
            <FormattedMessage id="outboundOrderBook.compoundManagement.sidebar.details.departure" />
        </span>
    );
};
