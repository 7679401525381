import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';
import { Arrival, CompoundLocation } from '../compoundManagement.types';
import { DisplayFreightForwarder } from '../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayCancellableData } from '../shared/dataDisplay/DisplayCancellableData';
import { DateTimeInfo } from '../shared/dataDisplay/DateTimeInfo';

export type ArrivalDetailsProps = {
    arrival: Arrival;
    compoundLocation: CompoundLocation;
};

export const ArrivalDetails: FunctionComponent<ArrivalDetailsProps> = ({ arrival, compoundLocation }) => {
    const rowClasses = 'display-flex gap-25 align-items-start';
    const arrivalCompletedAt = arrival.arrivalCompletedAt;
    const isCancelled = arrival.isCancelled;

    return (
        <div className={rowClasses}>
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.arrivalDate">
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DateTimeInfo date={arrivalCompletedAt} timezone={compoundLocation?.timeZone} />
                </DisplayCancellableData>
            </LabelledInfoCell>
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.carrier">
                <DisplayCancellableData isCancelled={isCancelled}>
                    <DisplayFreightForwarder freightForwarder={arrival.freightForwarder} />
                </DisplayCancellableData>
            </LabelledInfoCell>
        </div>
    );
};
