import { useMemo, useState } from 'react';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import {
    FilterModalButton,
    FilterModalDatePicker,
    FilterModalFooter,
    FilterModalSelect,
    FilterModalTextInput,
} from '../../sharedComponents/filter/FilterModal';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { compoundManagementSlice, getCompoundManagementModalFilter } from '../../reducers/compoundManagementSlice';
import { VehicleType } from '../../sharedComponents/common.types';
import moment, { Moment } from 'moment/moment';
import { isBefore, isDate } from 'date-fns';
import { CompoundManagementView } from '../compoundManagement.types';
import { isValidDate } from '../../sharedComponents/dateHelper';
import { SelectOption } from '@rio-cloud/rio-uikit/Select';

const getVehicleTypeOptions = (): SelectOption[] => {
    return Object.values(VehicleType).map((type) => ({
        id: type,
        label: <FormattedMessage id={`outboundOrderBook.common.vehicleType.${type.valueOf()}`} />,
    }));
};

const getValidText = (value: string | undefined): string | undefined => {
    const trimmedValue = value?.trim();
    return trimmedValue?.length === 0 ? undefined : trimmedValue;
};

const getValidDate = (dateValue: undefined | string | Date): Date | undefined => {
    return isDate(dateValue) ? (dateValue as Date) : undefined;
};

const validateDateTimeFields = (startDate: string | Date | undefined, endDate: string | Date | undefined) => {
    const hasStartDateTimeError = startDate !== undefined && !isValidDate(startDate);
    const hasEndDateTimeError = endDate !== undefined && !isValidDate(endDate);
    const hasTimeRangeError =
        !(hasEndDateTimeError || hasStartDateTimeError) &&
        startDate !== undefined &&
        endDate !== undefined &&
        isBefore(endDate as Date, startDate as Date);

    return {
        hasStartDateTimeError,
        hasEndDateTimeError,
        hasTimeRangeError,
    };
};

export interface LocalCompoundVehicleModalFilter {
    locationIdentifierFilter: string | undefined;
    vehicleTypeFilter: VehicleType | undefined;
    manufacturerFilter: string | undefined;
    arrivalDateStartAtFilter: Date | undefined | string;
    arrivalDateEndAtFilter: Date | undefined | string;
    departureDateStartAtFilter: Date | undefined | string;
    departureDateEndAtFilter: Date | undefined | string;
}

export const UNSET_COMPOUND_VEHICLE_MODAL_FILTER: LocalCompoundVehicleModalFilter = {
    locationIdentifierFilter: undefined,
    vehicleTypeFilter: undefined,
    manufacturerFilter: undefined,
    arrivalDateStartAtFilter: undefined,
    arrivalDateEndAtFilter: undefined,
    departureDateStartAtFilter: undefined,
    departureDateEndAtFilter: undefined,
};

interface CompoundManagementModalFilterProps {
    modalType: CompoundManagementView;
}

// eslint-disable-next-line max-lines-per-function
export const CompoundManagementFilterModal = (props: CompoundManagementModalFilterProps) => {
    const dispatch = useAppDispatch();
    const modalFilter = useAppSelector(getCompoundManagementModalFilter);
    const [showModal, setShowModal] = useState(false);
    const [localFilters, setLocalFilters] = useState<LocalCompoundVehicleModalFilter>(modalFilter);

    const isFilterActive =
        localFilters.locationIdentifierFilter !== undefined ||
        localFilters.vehicleTypeFilter !== undefined ||
        localFilters.manufacturerFilter !== undefined ||
        localFilters.arrivalDateStartAtFilter !== undefined ||
        localFilters.arrivalDateEndAtFilter !== undefined ||
        localFilters.departureDateStartAtFilter !== undefined ||
        localFilters.departureDateEndAtFilter !== undefined;

    const handleOnClickApply = () => {
        setLocalFilters({
            ...localFilters,
            locationIdentifierFilter: getValidText(localFilters.locationIdentifierFilter),
            manufacturerFilter: getValidText(localFilters.manufacturerFilter),
        });
        dispatch(
            compoundManagementSlice.actions.setCompoundManagementModalFilter({
                ...localFilters,
                arrivalDateStartAtFilter: getValidDate(localFilters.arrivalDateStartAtFilter),
                arrivalDateEndAtFilter: getValidDate(localFilters.arrivalDateEndAtFilter),
                departureDateStartAtFilter: getValidDate(localFilters.departureDateStartAtFilter),
                departureDateEndAtFilter: getValidDate(localFilters.departureDateEndAtFilter),
                locationIdentifierFilter: getValidText(localFilters.locationIdentifierFilter),
                manufacturerFilter: getValidText(localFilters.manufacturerFilter),
            }),
        );
        setShowModal(false);
    };

    const handleOnClickCancel = () => {
        setLocalFilters(modalFilter);
        setShowModal(false);
    };

    const resetFilters = () => {
        setLocalFilters(UNSET_COMPOUND_VEHICLE_MODAL_FILTER);
    };

    const nextDateValue = (date: Moment | string | undefined) =>
        moment.isMoment(date) ? date.toDate() : date === '' ? undefined : date;

    const {
        hasStartDateTimeError: hasArrivalStartDateTimeError,
        hasEndDateTimeError: hasArrivalEndDateTimeError,
        hasTimeRangeError: hasArrivalTimeRangeError,
    } = useMemo(
        () => validateDateTimeFields(localFilters.arrivalDateStartAtFilter, localFilters.arrivalDateEndAtFilter),
        [localFilters],
    );

    const {
        hasStartDateTimeError: hasDepartureStartDateTimeError,
        hasEndDateTimeError: hasDepartureEndDateTimeError,
        hasTimeRangeError: hasDepartureTimeRangeError,
    } = useMemo(
        () => validateDateTimeFields(localFilters.departureDateStartAtFilter, localFilters.departureDateEndAtFilter),
        [localFilters],
    );

    const content = (
        <div className="margin-bottom-20">
            <FilterModalTextInput
                id="location-id-filter"
                headerMessageId="outboundOrderBook.compoundManagement.table.toolbar.filter.locationId"
                placeholderMessageId="outboundOrderBook.common.filter.enterLocationId"
                value={localFilters.locationIdentifierFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({
                        ...prevState,
                        locationIdentifierFilter: value === '' ? undefined : value,
                    }));
                }}
            />

            <div className="margin-top-20 margin-bottom-20">
                <FilterModalSelect
                    id="vehicle-type-filter"
                    labelMessageId="outboundOrderBook.common.filter.dispatchType"
                    placeholderMessageId="outboundOrderBook.common.filter.selectDispatchType"
                    options={getVehicleTypeOptions()}
                    value={localFilters.vehicleTypeFilter}
                    onChange={(item) => {
                        setLocalFilters((prevState) => ({
                            ...prevState,
                            vehicleTypeFilter: item?.id as VehicleType | undefined,
                        }));
                    }}
                />
            </div>

            <FilterModalTextInput
                id="manufacturer-filter"
                headerMessageId="outboundOrderBook.common.filter.manufacturer"
                placeholderMessageId="outboundOrderBook.common.filter.enterManufacturer"
                value={localFilters.manufacturerFilter}
                onChange={(event) => {
                    const value = event.currentTarget.value;
                    setLocalFilters((prevState) => ({
                        ...prevState,
                        manufacturerFilter: value === '' ? undefined : value,
                    }));
                }}
            />

            <hr />
            <div className="margin-top-20">
                <label className="margin-bottom-10">
                    <FormattedMessage id="outboundOrderBook.common.filter.arrivalTimeframe" />
                </label>
                <div className="display-flex justify-content-start row">
                    <div className="col-6 padding-right-5">
                        <FilterModalDatePicker
                            id="arrival-date-from"
                            labelId="outboundPortal.transportAssignments.table.from"
                            hasFormatError={hasArrivalStartDateTimeError}
                            hasTimeRangeOrderError={false}
                            value={localFilters.arrivalDateStartAtFilter}
                            onChange={(date: Moment | string | undefined) =>
                                setLocalFilters((prevState) => ({
                                    ...prevState,
                                    arrivalDateStartAtFilter: nextDateValue(date),
                                }))
                            }
                        />
                    </div>
                    <div className="col-6 padding-left-5">
                        <FilterModalDatePicker
                            id="arrival-date-to"
                            labelId="outboundPortal.transportAssignments.table.to"
                            hasFormatError={hasArrivalEndDateTimeError}
                            hasTimeRangeOrderError={hasArrivalTimeRangeError}
                            value={localFilters.arrivalDateEndAtFilter}
                            onChange={(date: Moment | string | undefined) =>
                                setLocalFilters((prevState) => ({
                                    ...prevState,
                                    arrivalDateEndAtFilter: nextDateValue(date),
                                }))
                            }
                        />
                    </div>
                </div>
            </div>

            {props.modalType === CompoundManagementView.DEPARTED && (
                <div className="margin-top-20">
                    <label className="margin-bottom-10">
                        <FormattedMessage id="outboundOrderBook.common.filter.departureTimeframe" />
                    </label>
                    <div className="display-flex justify-content-start row">
                        <div className="col-6 padding-right-5">
                            <FilterModalDatePicker
                                id="departure-date-from"
                                labelId="outboundPortal.transportAssignments.table.from"
                                hasFormatError={hasDepartureStartDateTimeError}
                                hasTimeRangeOrderError={false}
                                value={localFilters.departureDateStartAtFilter}
                                onChange={(date: Moment | string | undefined) =>
                                    setLocalFilters((prevState) => ({
                                        ...prevState,
                                        departureDateStartAtFilter: nextDateValue(date),
                                    }))
                                }
                            />
                        </div>
                        <div className="col-6 padding-left-5">
                            <FilterModalDatePicker
                                id="departure-date-to"
                                labelId="outboundPortal.transportAssignments.table.to"
                                hasFormatError={hasDepartureEndDateTimeError}
                                hasTimeRangeOrderError={hasDepartureTimeRangeError}
                                value={localFilters.departureDateEndAtFilter}
                                onChange={(date: Moment | string | undefined) =>
                                    setLocalFilters((prevState) => ({
                                        ...prevState,
                                        departureDateEndAtFilter: nextDateValue(date),
                                    }))
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <>
            <FilterModalButton isFilterActive={isFilterActive} setShowFilterModal={setShowModal} />
            <Dialog
                show={showModal}
                bsSize={Dialog.SIZE_SM}
                title={<FormattedMessage id="outboundOrderBook.common.table.toolbar.filter.title" />}
                body={content}
                footer={
                    <FilterModalFooter
                        handleOnClickApply={handleOnClickApply}
                        handleOnClickCancel={handleOnClickCancel}
                        resetFilters={resetFilters}
                        isApplyButtonDisabled={
                            hasArrivalStartDateTimeError ||
                            hasArrivalEndDateTimeError ||
                            hasDepartureStartDateTimeError ||
                            hasDepartureEndDateTimeError ||
                            hasArrivalTimeRangeError ||
                            hasDepartureTimeRangeError
                        }
                    />
                }
                onHide={handleOnClickCancel}
            />
        </>
    );
};
