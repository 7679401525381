import SortArrows from '@rio-cloud/rio-uikit/SortArrows';
import TableViewToggles, { TableViewTogglesViewType } from '@rio-cloud/rio-uikit/TableViewToggles';
import classNames from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

// compatible with UiKit
export enum SortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export type TableSort<TableColumnEnum extends string> = {
    dir: SortDirection;
    column: TableColumnEnum;
};

export type ColumnDefinition<TableColumnEnum extends string> = {
    column: TableColumnEnum;
    labelId?: string;
    sortable: boolean;
};

export const getSortDir = <TableColumnEnum extends string>(
    col: TableColumnEnum,
    previousSorting: TableSort<TableColumnEnum>,
): SortDirection => {
    if (col === previousSorting.column) {
        return previousSorting.dir === SortDirection.ASCENDING ? SortDirection.DESCENDING : SortDirection.ASCENDING;
    } else {
        return SortDirection.ASCENDING;
    }
};

export const defaultTableHeaderClassnames = 'align-middle user-select-none';

export const getTableClassNames = (items: readonly any[], viewType: TableViewTogglesViewType) =>
    classNames(
        'table',
        'table-layout-fixed',
        'table-column-overflow-hidden',
        'table-bordered',
        'table-sticky',
        'table-head-filled',
        items.length > 0 && 'table-hover',
        viewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'table-cards table-single-card',
        viewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'table-cards table-multi-cards',
    );

type SortColumnTableHeaderProps<TableColumnEnum extends string> = {
    col: TableColumnEnum;
    childClassNames?: classNames.Argument;
    sortingHandler: (col: TableColumnEnum) => void;
    currentSort: TableSort<TableColumnEnum>;
    labelId: string | undefined;
};

export const SortColumnTableHeaderFactory = <TableColumnEnum extends string>() => {
    const SortColumnTableHeader = forwardRef(
        (props: SortColumnTableHeaderProps<TableColumnEnum>, ref: ForwardedRef<HTMLTableCellElement>) => {
            const { col } = props;

            return (
                <th
                    ref={ref}
                    className={classNames(defaultTableHeaderClassnames, 'sort-column')}
                    onClick={() => props.sortingHandler(col)}
                >
                    <span className={classNames(props.childClassNames)}>
                        {props.currentSort.column === col ? (
                            <SortArrows direction={props.currentSort.dir} />
                        ) : (
                            <SortArrows />
                        )}
                        {props.labelId !== undefined ? <FormattedMessage id={props.labelId} /> : undefined}
                    </span>
                </th>
            );
        },
    );
    SortColumnTableHeader.displayName = 'SortColumnTableHeader';
    return SortColumnTableHeader;
};

export const ColumnTableHeader = (props: { labelId: string | undefined; additionalClassNames?: string }) => {
    return (
        <th className={classNames(defaultTableHeaderClassnames, props.additionalClassNames)}>
            {props.labelId !== undefined && <FormattedMessage id={props.labelId} />}
        </th>
    );
};
