import classNames from 'classnames';
import {
    TransportAssignment,
    TransportAssignmentStatus,
    TransportAssignmentType,
    TransportOrderBundle,
    TransportOrderBundleDeliveries,
} from '../transportAssignment.types';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { getSelectedTransportAssignment, transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { getHasActiveIncident } from '../TransportAssignmentUtils';
import { TransportAssignmentsTableActionButton } from './TransportAssignmentsTableActionButton';
import {
    AddressField,
    TransportOrderBundleDeliveryDate,
    TransportOrderBundleModelGroupCell,
    TransportOrderBundleUnloading,
    TransportOrderBundleVehiclesCell,
    TransportOrderCapacityOrderVehiclesCell,
    UnloadingAddress,
} from './TransportAssignmentsTableRowBaseComponents';
import { DateTimeIntervalCell } from '../TransportAssignmentBaseComponents';
import { getRowAlertConfiguration } from '../tableRowStyleAndSettings';
import { ReactNode } from 'react';
import { transportAssignmentColumnDefinition, TransportAssignmentTableColumn } from './TransportAssignmentTableColumns';
import { NoValue } from '../../sharedComponents/NoValue';
import { AlertIcon, DateTime } from '../../sharedComponents/table/BaseTableComponents';
import { AlertConfig } from '../../sharedComponents/common.types';
import { FeatureToggles, useFeatureToggle } from '../../../../configuration/featureToggle/toggleHook';
import { StatusLabel } from '../../sharedComponents/StatusLabel';

const filterOutCancelledDeliveries = (transportOrderBundleDeliveries: TransportOrderBundleDeliveries[]) =>
    transportOrderBundleDeliveries.filter(
        (delivery) =>
            delivery.originatedTransportOrders.filter((transportOrder) => !transportOrder.isCancelled).length > 0,
    );

const getTransportOrderBundleDeliveries = (transportOrderBundle: TransportOrderBundle) =>
    transportOrderBundle.status === TransportAssignmentStatus.CANCELLATION
        ? transportOrderBundle.deliveries
        : filterOutCancelledDeliveries(transportOrderBundle.deliveries);

// eslint-disable-next-line max-lines-per-function
const renderTableDataContent = (
    column: TransportAssignmentTableColumn,
    transportAssignment: TransportAssignment,
    rowConfig: AlertConfig[],
): JSX.Element | ReactNode => {
    const isSingleOrder = transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER;
    const isBundle = transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER_BUNDLE;
    const isCapacityOrder = transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER;
    switch (column) {
        case TransportAssignmentTableColumn.HAS_CHANGES:
            return <AlertIcon rowConfig={rowConfig} />;
        case TransportAssignmentTableColumn.MODEL:
            if (isSingleOrder) {
                return (
                    <div
                        className={classNames('ellipsis-1', {
                            'text-decoration-line-through':
                                transportAssignment.status === TransportAssignmentStatus.CANCELLATION,
                        })}
                    >
                        {transportAssignment.vehicle.model.name}
                    </div>
                );
            }
            if (isBundle) {
                return (
                    <TransportOrderBundleVehiclesCell
                        transportOrderBundleDeliveries={getTransportOrderBundleDeliveries(transportAssignment)}
                    />
                );
            }
            if (isCapacityOrder) {
                return <TransportOrderCapacityOrderVehiclesCell transportCapacityOrder={transportAssignment} />;
            }
            return <NoValue />;
        case TransportAssignmentTableColumn.MODEL_GROUP:
            if (isSingleOrder) {
                return (
                    <div
                        className={classNames({
                            'text-decoration-line-through':
                                transportAssignment.status === TransportAssignmentStatus.CANCELLATION,
                        })}
                    >
                        {transportAssignment.vehicle.model.modelGroup}
                    </div>
                );
            }
            if (isBundle) {
                return (
                    <TransportOrderBundleModelGroupCell
                        transportOrderBundleDeliveries={getTransportOrderBundleDeliveries(transportAssignment)}
                    />
                );
            }
            return <NoValue />;
        case TransportAssignmentTableColumn.FROM:
            const address = transportAssignment.loading.addressAndContact.address;
            if (address === undefined) {
                return <NoValue />;
            }
            return <AddressField address={address} />;
        case TransportAssignmentTableColumn.REQUESTED_PICKUP:
            return (
                <DateTimeIntervalCell
                    dateTimeInterval={transportAssignment.loading.requestedPickUpDate}
                    timeZone={transportAssignment.loading.timeZone}
                />
            );
        case TransportAssignmentTableColumn.TO:
            if (isBundle) {
                return (
                    <TransportOrderBundleUnloading
                        transportOrderBundleDeliveries={getTransportOrderBundleDeliveries(transportAssignment)}
                    />
                );
            } else {
                return (
                    <UnloadingAddress
                        address={transportAssignment.unloading.addressAndContact?.address}
                        hasSelectableUnloadingAddress={
                            isSingleOrder ? transportAssignment.hasSelectableUnloadingAddress : false
                        }
                    />
                );
            }
        case TransportAssignmentTableColumn.REQUESTED_DELIVERY:
            if (isBundle) {
                return (
                    <TransportOrderBundleDeliveryDate
                        transportOrderBundleDeliveries={getTransportOrderBundleDeliveries(transportAssignment)}
                    />
                );
            } else {
                if (transportAssignment.unloading.requestedDeliveryDate !== undefined) {
                    return (
                        <DateTime
                            dateTime={transportAssignment.unloading.requestedDeliveryDate}
                            timeZone={transportAssignment.unloading.timeZone}
                        />
                    );
                } else {
                    return <NoValue />;
                }
            }
        case TransportAssignmentTableColumn.MEANS_OF_TRANSPORT:
            return (
                transportAssignment.meansOfTransport?.id ?? (
                    <NoValue
                        messageId={
                            transportAssignment.status === TransportAssignmentStatus.CANCELLATION
                                ? undefined
                                : 'outboundOrderBook.transportAssignments.table.enterMeansOfTransportId'
                        }
                    />
                )
            );
    }
};

const TableRowData = (props: {
    column: TransportAssignmentTableColumn;
    transportAssignment: TransportAssignment;
    rowConfig: AlertConfig[];
    isArchiveTab: boolean;
}) => {
    const { column, transportAssignment, rowConfig, isArchiveTab } = props;
    const isSingleOrder = transportAssignment.type === TransportAssignmentType.TRANSPORT_ORDER;
    const isCapacityOrder = transportAssignment.type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER;
    const trackingFeatureEnabled = useFeatureToggle(FeatureToggles.TRACKING, false).value;

    const assignmentHasTrackingEnabled = trackingFeatureEnabled && transportAssignment.isAutomaticallyTracked;

    switch (column) {
        case TransportAssignmentTableColumn.EXTERNAL_ID:
            return (
                <td className="ellipsis-1" key={column}>
                    {isCapacityOrder
                        ? transportAssignment.bundleId
                        : isSingleOrder
                          ? transportAssignment.externalOrderId
                          : transportAssignment.id}
                </td>
            );
        case TransportAssignmentTableColumn.STATUS:
            return (
                <>
                    <td key={column} className="no-line-wrap">
                        {assignmentHasTrackingEnabled && (
                            <>
                                <span
                                    className={classNames(
                                        'rioglyph',
                                        'text-size-18',
                                        'padding-right-5',
                                        'rioglyph rioglyph-geofence',
                                        'text-color-highlight-light',
                                        'text-size-20',
                                    )}
                                />
                            </>
                        )}
                        <span
                            className={assignmentHasTrackingEnabled || !trackingFeatureEnabled ? '' : 'margin-left-25'}
                        >
                            <StatusLabel status={transportAssignment.status} />
                        </span>
                    </td>
                </>
            );
        case TransportAssignmentTableColumn.ACTION:
            return isArchiveTab ? null : (
                <td className="text-center" key={column}>
                    <TransportAssignmentsTableActionButton transportAssignment={transportAssignment} />
                </td>
            );
        default:
            return <td key={column}>{renderTableDataContent(column, transportAssignment, rowConfig)}</td>;
    }
};

export const TransportAssignmentRow = (props: { transportAssignment: TransportAssignment; isArchivedTab: boolean }) => {
    const { id, status, hasUnacknowledgedExternalChanges, type } = props.transportAssignment;
    const selectedTransportOrder = useAppSelector(getSelectedTransportAssignment);
    const dispatch = useAppDispatch();

    const isSelected = selectedTransportOrder?.id === id;
    const hasActiveIncident = getHasActiveIncident(props.transportAssignment);
    const isCapacity = type === TransportAssignmentType.TRANSPORT_CAPACITY_ORDER;
    const rowConfig = getRowAlertConfiguration(hasUnacknowledgedExternalChanges, status, hasActiveIncident, isCapacity);
    const highlight = rowConfig.map((config) => config.highlight)[0];

    const handleRowClick = () => {
        if (isSelected) {
            dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
        } else {
            dispatch(transportAssignmentsSlice.actions.selectTransportAssignment(props.transportAssignment));
        }
    };

    return (
        <tr
            key={props.transportAssignment.id}
            onClick={handleRowClick}
            className={classNames('cursor-pointer', highlight, { active: isSelected })}
        >
            {transportAssignmentColumnDefinition.map((def) => (
                <TableRowData
                    key={def.column}
                    column={def.column}
                    transportAssignment={props.transportAssignment}
                    rowConfig={rowConfig}
                    isArchiveTab={props.isArchivedTab}
                />
            ))}
        </tr>
    );
};
