import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
    ExecutionStatusChanged,
    ReportedIncident,
    SelectableUnloadingAddress,
    TransportAssignmentPage,
    TransportCapacityOrderConfirmation,
    TransportOrderConfirmation,
} from '../transportAssignment/transportAssignment.types';
import { config } from '../../../config';
import {
    mapToSelectableUnloadingAddresses,
    mapTransportAssignmentPage,
} from './mappers/transportAssignment/transportAssignmentsMapper';
import { decodeResponse, validateResponse } from './responseUtil';
import { TransportAssignmentPageCodec } from './types/transportAssignment/transportAssignmentsApi.types';
import {
    mapToApiAcknowledgedTransportCapacityOrder,
    mapToApiAcknowledgedTransportOrders,
    mapToApiArchivedTransportOrders,
    mapToApiTransportAssignmentStatusChange,
    mapToApiTransportCapacityOrderConfirmation,
    mapToApiTransportOrderConfirmation,
    mapToIncidentApi,
    mapToTransportAssignmentQueryParameters,
} from './mappers/outgoingRequestMappers';
import { prepareHeaders } from './utils';
import { SelectableUnloadingAddressesCodec } from './types/transportAssignment/addressAndContactApi.types';
import { FileExport, VehicleId } from '../sharedComponents/common.types';
import { TableSort } from '../sharedComponents/table/SortedTableHeader';
import { TransportAssignmentTableColumn } from '../transportAssignment/table/TransportAssignmentTableColumns';

export const ACKNOWLEDGED_TRANSPORT_ORDERS_PATH = 'acknowledged-transport-orders';
export const ACKNOWLEDGED_TRANSPORT_CAPACITY_ORDERS_PATH = 'has-unacknowledged-external-changes';
export const CONFIRMATIONS_PATH = 'confirmations';
export const TRANSPORT_ORDERS_PATH = 'transport-orders';
export const TRANSPORT_CAPACITY_ORDERS_PATH = 'transport-capacity-orders';
export const TRANSPORT_ASSIGNMENTS_PATH = 'transport-assignments';
export const CHANGE_TRANSPORT_STATUS_PATH = 'statuses';
export const ARCHIVED_TRANSPORT_ORDERS_PATH = 'archived-transport-orders';
export const TERMS_AND_CONDITIONS_AGREEMENT_PATH = 'terms-and-conditions-agreements';
export const ACTIVATION_STATUSES_PATH = 'activation-statuses';
export const ACTIVATION_REQUESTS_PATH = 'activation-requests';
export const SELECTABLE_UNLOADING_PATH = 'selectable-unloading-addresses';
export const INCIDENT_PATH = 'incidents';
export const DOWNLOAD_EXCEL = 'exports';
export const FILE_NAME_REGEX = /filename="?([^"]+)"?$/;

export type GetTransportAssignmentsQueryParams = {
    statuses?: FilterableApiStatus[];
    archived?: boolean;
    sortBy?: TableSort<TransportAssignmentTableColumn>;
    requestedPickUpFrom?: Date;
    requestedPickUpTo?: Date;
    unloadingCity?: string;
    loadingCity?: string;
    search?: string;
    limit?: number;
};

export enum FilterableApiStatus {
    CANCELLATION = 'cancellation',
    CONFIRMED = 'confirmed',
    UNCONFIRMED = 'unconfirmed',
    UNLOADED = 'unloaded',
    LOADED = 'loaded',
}

type PostConfirmationRequestProps = {
    confirmation: TransportOrderConfirmation;
};

type PostTransportCapacityOrderConfirmationRequestProps = {
    transportCapacityOrderId: string;
    confirmation: TransportCapacityOrderConfirmation;
};

type PostAcknowledgementOfExternalTransportOrderChangesProps = {
    transportOrderIds: string[];
};

type PutAcknowledgementOfExternalTransportCapacityOrderChangesProps = {
    transportCapacityOrderId: string;
    hasUnacknowledgedExternalChanges: boolean;
};

type PostStatusTransportAssignmentProps = {
    executionStatusChanged: ExecutionStatusChanged;
};

type PostArchiveTransportAssignmentProps = {
    transportOrderIds: string[];
    isArchived: boolean;
};

type GetUnloadingAddressesProps = {
    transportOrderId: string;
};

export type PostIncidentProps = {
    changedAt: Date;
    incidents: {
        incident: Pick<ReportedIncident, 'type' | 'description' | 'isEmptyRun'>;
        vehicleId: VehicleId;
        transportOrderId: string;
    }[];
};

const transportAssignmentTag = 'TransportAssignment';

export const transportOrderApi = createApi({
    reducerPath: 'outboundApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.FINISHED_VEHICLES_SERVICE,
        prepareHeaders,
    }),
    tagTypes: [transportAssignmentTag],
    // eslint-disable-next-line max-lines-per-function
    endpoints: (builder) => ({
        getTransportAssignments: builder.query<TransportAssignmentPage, GetTransportAssignmentsQueryParams | void>({
            query: (queryParameters) => ({
                url: TRANSPORT_ASSIGNMENTS_PATH,
                // the params object is automatically serialized and appended => ?attribute=value
                params: mapToTransportAssignmentQueryParameters(queryParameters),
                validateStatus: validateResponse(TransportAssignmentPageCodec),
            }),
            transformResponse: decodeResponse(TransportAssignmentPageCodec, mapTransportAssignmentPage),
            providesTags: [transportAssignmentTag],
        }),
        postTransportOrderConfirmation: builder.mutation<void, PostConfirmationRequestProps>({
            query: ({ confirmation }) => ({
                url: `${TRANSPORT_ORDERS_PATH}/${CONFIRMATIONS_PATH}`,
                method: 'POST',
                body: mapToApiTransportOrderConfirmation(confirmation),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        postTransportCapacityOrderConfirmation: builder.mutation<
            void,
            PostTransportCapacityOrderConfirmationRequestProps
        >({
            query: ({ transportCapacityOrderId, confirmation }) => ({
                url: `${TRANSPORT_CAPACITY_ORDERS_PATH}/${transportCapacityOrderId}/${CONFIRMATIONS_PATH}`,
                method: 'POST',
                body: mapToApiTransportCapacityOrderConfirmation(confirmation),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        postAcknowledgementOfExternalTransportOrderChanges: builder.mutation<
            void,
            PostAcknowledgementOfExternalTransportOrderChangesProps
        >({
            query: ({ transportOrderIds }) => ({
                url: ACKNOWLEDGED_TRANSPORT_ORDERS_PATH,
                method: 'POST',
                body: mapToApiAcknowledgedTransportOrders(transportOrderIds),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        putAcknowledgementOfExternalTransportCapacityOrderChanges: builder.mutation<
            void,
            PutAcknowledgementOfExternalTransportCapacityOrderChangesProps
        >({
            query: ({ transportCapacityOrderId, hasUnacknowledgedExternalChanges }) => ({
                url: `${TRANSPORT_CAPACITY_ORDERS_PATH}/${transportCapacityOrderId}/${ACKNOWLEDGED_TRANSPORT_CAPACITY_ORDERS_PATH}`,
                method: 'PUT',
                body: mapToApiAcknowledgedTransportCapacityOrder(hasUnacknowledgedExternalChanges),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        postStatusTransportAssignment: builder.mutation<void, PostStatusTransportAssignmentProps>({
            query: ({ executionStatusChanged }) => ({
                url: `${TRANSPORT_ORDERS_PATH}/${CHANGE_TRANSPORT_STATUS_PATH}`,
                method: 'POST',
                body: mapToApiTransportAssignmentStatusChange(executionStatusChanged),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        postArchiveTransportAssignment: builder.mutation<void, PostArchiveTransportAssignmentProps>({
            query: (archivedTransportOrders) => ({
                url: ARCHIVED_TRANSPORT_ORDERS_PATH,
                method: 'POST',
                body: mapToApiArchivedTransportOrders(archivedTransportOrders),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        getUnloadingAddresses: builder.query<SelectableUnloadingAddress[], GetUnloadingAddressesProps>({
            query: (props) => ({
                url: `${TRANSPORT_ORDERS_PATH}/${props.transportOrderId}/${SELECTABLE_UNLOADING_PATH}`,
                validateStatus: validateResponse(SelectableUnloadingAddressesCodec),
            }),
            transformResponse: decodeResponse(SelectableUnloadingAddressesCodec, mapToSelectableUnloadingAddresses),
        }),
        postIncident: builder.mutation<void, PostIncidentProps>({
            query: (postIncidentProps) => ({
                url: `${TRANSPORT_ORDERS_PATH}/${INCIDENT_PATH}`,
                method: 'POST',
                body: mapToIncidentApi(postIncidentProps),
            }),
            invalidatesTags: [transportAssignmentTag],
        }),
        getExcel: builder.mutation<FileExport, void>({
            query: () => ({
                url: `${TRANSPORT_ORDERS_PATH}/${DOWNLOAD_EXCEL}`,
                method: 'GET',
                cache: 'no-cache',
                responseHandler: async (response): Promise<FileExport> => ({
                    fileLink: window.URL.createObjectURL(await response.blob()),
                    fileName: response.headers.get('Content-Disposition')?.match(FILE_NAME_REGEX)?.at(1),
                }),
            }),
        }),
    }),
});

export const {
    useGetTransportAssignmentsQuery,
    usePostTransportOrderConfirmationMutation,
    usePostTransportCapacityOrderConfirmationMutation,
    usePostAcknowledgementOfExternalTransportOrderChangesMutation,
    usePutAcknowledgementOfExternalTransportCapacityOrderChangesMutation,
    usePostStatusTransportAssignmentMutation,
    usePostArchiveTransportAssignmentMutation,
    useGetUnloadingAddressesQuery,
    usePostIncidentMutation,
    useGetExcelMutation,
} = transportOrderApi;
