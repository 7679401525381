import { Vehicle } from '../../compoundManagement.types';

export const DisplayModelName = ({ vehicle }: { vehicle: Vehicle }) => {
    return (
        <span>
            <span className="rioglyph rioglyph-car text-size-18 padding-right-5" />
            {vehicle.model.name}
        </span>
    );
};
