import { Address, FuelType, ModelCategory, VehicleDimensions, VehicleType } from '../sharedComponents/common.types';
import { MeansOfTransportType } from '../transportAssignment/transportAssignment.types';

export enum CompoundManagementView {
    INCOMING_VEHICLES = 'outboundOrderBook.compoundManagement.toolbar.incoming',
    INVENTORY = 'outboundOrderBook.compoundManagement.toolbar.inventory',
    OUTGOING_VEHICLES = 'outboundOrderBook.compoundManagement.toolbar.outgoing',
    DEPARTED = 'outboundOrderBook.compoundManagement.toolbar.departed',
}

export type CompoundLocation = {
    readonly identifier: string;
    readonly address: Address;
    readonly timeZone: string;
};

export type FreightForwarder = {
    readonly name: string;
    readonly additionalName: string | undefined;
};

export type MeansOfTransport = {
    readonly id?: string;
    readonly type: MeansOfTransportType;
};

export type ExpectedArrival = {
    readonly expectedArrivalAt: Date;
    readonly meansOfTransport: MeansOfTransport;
    readonly freightForwarder: FreightForwarder | undefined;
    readonly isCancelled: boolean;
};

export type Arrival = {
    readonly arrivalCompletedAt: Date;
    readonly freightForwarder: FreightForwarder | undefined;
    readonly isCancelled: boolean;
};

export type ExpectedDeparture = {
    readonly expectedDepartureAt: Date;
    readonly meansOfTransport: MeansOfTransport;
    readonly freightForwarder: FreightForwarder | undefined;
    readonly isCancelled: boolean;
};

export type Departure = {
    readonly departureCompletedAt: Date;
    readonly freightForwarder: FreightForwarder | undefined;
};

export type Model = {
    readonly name: string;
    readonly version: string | undefined;
    /* note: called modelGroup in transport assignment context, feel free to align if needed */
    readonly group: string;
    readonly manufacturer: string | undefined;
    readonly fuelType: FuelType | undefined;
    readonly category: ModelCategory | undefined;
};

export type Vehicle = {
    readonly vin: string;
    readonly model: Model;
    readonly vehicleType: VehicleType | undefined;
    readonly manufacturedAt: Date | undefined;
    readonly finalDestination: FinalDestination | undefined;
    readonly dimensions: VehicleDimensions | undefined;
    readonly weight: number | undefined;
    readonly colour: string | undefined;
    readonly licensePlate: string | undefined;
};

export type FinalDestination = {
    identifier: string | undefined;
    address: Partial<Address> | undefined;
};

export type IncomingVehicle = {
    readonly id: string;
    readonly compoundLocation: CompoundLocation;
    readonly arrival: ExpectedArrival;
    readonly vehicle: Vehicle;
};

export type InventoryVehicle = {
    readonly id: string;
    readonly compoundLocation: CompoundLocation;
    readonly arrival: Arrival;
    readonly vehicle: Vehicle;
};

export type OutgoingVehicle = {
    readonly id: string;
    readonly compoundLocation: CompoundLocation;
    readonly departure: ExpectedDeparture;
    readonly vehicle: Vehicle;
};

export type DepartedVehicle = {
    readonly id: string;
    readonly compoundLocation: CompoundLocation;
    readonly arrival: Arrival;
    readonly departure: Departure;
    readonly vehicle: Vehicle;
};

export type CompoundVehicle = IncomingVehicle | InventoryVehicle | OutgoingVehicle | DepartedVehicle;

export type IncomingVehiclesPage = {
    readonly items: IncomingVehicle[];
    readonly hasMore: boolean;
};

export type InventoryVehiclesPage = {
    readonly items: InventoryVehicle[];
    readonly hasMore: boolean;
};

export type OutgoingVehiclesPage = {
    readonly items: OutgoingVehicle[];
    readonly hasMore: boolean;
};

export type DepartedVehiclesPage = {
    readonly items: DepartedVehicle[];
    readonly hasMore: boolean;
};

export type CompletionStatusChange = {
    readonly statusAt: Date;
};
