import { FormattedMessage } from 'react-intl';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';

type TrackingEnabledBannerProps = {
    transportMeansId: string;
};
export const TrackingEnabledBanner = ({ transportMeansId }: TrackingEnabledBannerProps) => {
    return (
        <div className="padding-x-20 padding-y-15 bg-primary">
            <div>
                <span className="text-color-highlight-light text-size-20 rioglyph rioglyph-geofence padding-right-10" />
                <span className="text-medium text-decoration-underline-offset-3">{transportMeansId}</span>
                &nbsp;
                <FormattedMessage id="outboundPortal.transportAssignments.sidebar.tracking.info" />
                <span className="padding-left-5">
                    <SimpleTooltip
                        content={
                            <FormattedMessage id="outboundPortal.transportAssignments.sidebar.tracking.info.details" />
                        }
                        placement={Tooltip.BOTTOM_START}
                    >
                        <span className="text-size-16 rioglyph rioglyph-info-sign" />
                    </SimpleTooltip>
                </span>
            </div>
        </div>
    );
};
