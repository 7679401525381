import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';
import { Vehicle } from '../compoundManagement.types';
import { NoValue } from '../../sharedComponents/NoValue';
import { formatDateInTimeZone } from '../../sharedComponents/dateHelper';

type DateInfoProps = {
    date: Date;
};

const DateInfo = ({ date }: DateInfoProps) => (
    <span>
        <div>{formatDateInTimeZone(date, 'UTC')}</div>
    </span>
);

type CompoundVehicleVINProps = {
    vehicle: Vehicle;
};

export const CompoundVehicleVIN: FunctionComponent<CompoundVehicleVINProps> = ({ vehicle }) => {
    return (
        <div
            className={classNames(
                'border-color-light',
                'border-style-solid',
                'border-width-1',
                'border-bottom-only',
                'bg-lightest',
                'display-flex',
                'gap-25',
                'padding-y-25',
                'padding-left-25',
            )}
        >
            <LabelledInfoCell
                labelMessageId="outboundOrderBook.common.heading.vin"
                className="text-size-16 text-medium"
            >
                {vehicle.vin}
            </LabelledInfoCell>
            <LabelledInfoCell
                labelMessageId="outboundOrderBook.compoundManagement.sidebar.manufacturedAt"
                className="text-size-16"
            >
                {vehicle.manufacturedAt !== undefined ? <DateInfo date={vehicle.manufacturedAt} /> : <NoValue />}
            </LabelledInfoCell>
        </div>
    );
};
