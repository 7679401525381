import noop from 'lodash/noop';
import { FormattedMessage } from 'react-intl';
import { SortDirection } from '../../../sharedComponents/table/SortedTableHeader';
import { OutgoingVehicle } from '../../compoundManagement.types';
import { Table } from '../../shared/table/Table';
import { CompoundManagementTableRow } from '../../table/CompoundManagementTableRow';
import { outgoingVehiclesColumnDefinition, OutgoingVehiclesTableColumn } from './OutgoingVehiclesTableColumn';
import { OutgoingVehiclesTableDataContent } from './OutgoingVehiclesTableDataContent';

type OutgoingVehiclesTableProps = {
    readonly vehicles: readonly OutgoingVehicle[];
};

export const OutgoingVehiclesTable = ({ vehicles }: OutgoingVehiclesTableProps) => {
    return (
        <Table
            columnDefinition={outgoingVehiclesColumnDefinition}
            sorting={{ dir: SortDirection.ASCENDING, column: OutgoingVehiclesTableColumn.EXPECTED_DEPARTURE_DATE }}
            setSorting={noop}
            items={vehicles}
            notFoundMessage={<FormattedMessage id="outboundOrderBook.compoundManagement.outgoingVehicles.notFound" />}
        >
            {vehicles.map((vehicle: OutgoingVehicle) => (
                <CompoundManagementTableRow
                    key={vehicle.id}
                    highlight={vehicle.departure.isCancelled ? 'danger' : undefined}
                    columnDefinition={outgoingVehiclesColumnDefinition}
                    vehicle={vehicle}
                >
                    {(column) => <OutgoingVehiclesTableDataContent column={column} vehicle={vehicle} />}
                </CompoundManagementTableRow>
            ))}
        </Table>
    );
};
