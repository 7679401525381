import { FormattedMessage } from 'react-intl';
import { formatDateInTimeZone, formatTimeInTimeZone } from '../../../sharedComponents/dateHelper';
import { ExecutionEventType, ExecutionStatus } from '../../transportAssignment.types';
import classNames from 'classnames';
import Tooltip from '@rio-cloud/rio-uikit/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/OverlayTrigger';

type ExecutionStatusDateProps = {
    status: ExecutionStatus;
    hasActiveIncident: boolean;
    eventType: ExecutionEventType | undefined;
    value?: Date;
    timeZone: string;
};

export const ExecutionStatusDate = ({
    status,
    hasActiveIncident,
    eventType,
    value,
    timeZone,
}: ExecutionStatusDateProps) => {
    const styling = 'padding-right-2 pull-left margin-right-5 width-25pct text-right';

    if (hasActiveIncident) {
        return (
            <span className={classNames('text-color-light', styling)}>
                <>
                    <span className="rioglyph rioglyph-damages text-color-gray text-size-20 aria-hidden" />
                    <FormattedMessage id="outboundPortal.transportAssignments.incident" />
                </>
            </span>
        );
    }

    if (value === undefined) {
        return (
            <span className={classNames('text-color-light', styling)}>
                <FormattedMessage
                    id={`outboundPortal.transportAssignments.status.not${
                        status.valueOf().charAt(0).toUpperCase() + status.valueOf().slice(1)
                    }`}
                />
            </span>
        );
    }

    return (
        <span className={classNames('text-color-text-color-success', styling)}>
            <StatusIcon withTracking={eventType === ExecutionEventType.AUTOMATIC} />
            <time title={`${formatDateInTimeZone(value, timeZone)} ${formatTimeInTimeZone(value, timeZone)}`}>
                {formatDateInTimeZone(value, timeZone)}
            </time>
        </span>
    );
};

const StatusIcon = ({ withTracking }: { withTracking: boolean }) => {
    const icon = (
        <span
            data-testid="execution-status-icon"
            className={classNames('margin-right-2 rioglyph', {
                'rioglyph-geofence': withTracking,
                'rioglyph-ok-sign': !withTracking,
            })}
        />
    );

    if (withTracking) {
        return (
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip textAlignment="left" width="auto">
                        <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.generatedFromTracking" />
                    </Tooltip>
                }
            >
                {icon}
            </OverlayTrigger>
        );
    } else {
        return icon;
    }
};
