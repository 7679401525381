import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config';
import {
    CompletionStatusChange,
    DepartedVehiclesPage,
    IncomingVehiclesPage,
    InventoryVehiclesPage,
    OutgoingVehiclesPage,
} from '../compoundManagement/compoundManagement.types';
import type { DepartedVehiclesTableColumn } from '../compoundManagement/departed/table/DepartedVehiclesTableColumn';
import type { IncomingVehiclesTableColumn } from '../compoundManagement/incoming/table/IncomingVehiclesTableColumn';
import type { VehicleInventoryTableColumn } from '../compoundManagement/inventory/table/VehicleInventoryTableColumn';
import type { OutgoingVehiclesTableColumn } from '../compoundManagement/outgoing/table/OutgoingVehiclesTableColumn';
import { VehicleType } from '../sharedComponents/common.types';
import { TableSort } from '../sharedComponents/table/SortedTableHeader';
import { mapToApiStatusCompletion } from './mappers/compoundManagement/completionMapper';
import {
    mapToDepartedVehicleQueryParameters,
    mapToDepartedVehiclesPage,
    mapToIncomingVehicleQueryParameters,
    mapToIncomingVehiclesPage,
    mapToInventoryVehicleQueryParameters,
    mapToInventoryVehiclesPage,
    mapToOutgoingVehicleQueryParameters,
    mapToOutgoingVehiclesPage,
} from './mappers/compoundManagement/compoundManagementMappers';
import { decodeResponse, validateResponse } from './responseUtil';
import {
    DepartedVehiclesPageCodec,
    IncomingVehiclesPageCodec,
    InventoryVehiclesPageCodec,
    OutgoingVehiclesPageCodec,
} from './types/compoundManagement/compoundManagementApi.types';
import { prepareHeaders } from './utils';

export const COMPOUND_MANAGEMENT_INCOMING_VEHICLES_PATH = 'compound-incoming-vehicles';
export const COMPOUND_MANAGEMENT_INVENTORY_VEHICLES_PATH = 'compound-inventory-vehicles';
export const COMPOUND_MANAGEMENT_OUTGOING_VEHICLES_PATH = 'compound-outgoing-vehicles';
export const COMPOUND_MANAGEMENT_DEPARTED_VEHICLES_PATH = 'compound-departed-vehicles';
export const COMPOUND_MANAGEMENT_VEHICLES_PATH = 'compound-vehicles';
export const COMPOUND_MANAGEMENT_ARRIVAL_COMPLETIONS_PATH = 'arrival-completions';
export const COMPOUND_MANAGEMENT_DEPARTURE_COMPLETIONS_PATH = 'departure-completions';

export type GetIncomingVehiclesQueryParams = {
    locationId?: string;
    vehicleType?: VehicleType;
    manufacturer?: string;
    expectedArrivalDateStartAt?: Date;
    expectedArrivalDateEndAt?: Date;
    vin?: string;
    sortBy?: TableSort<IncomingVehiclesTableColumn>;
    limit?: number;
};

export type GetInventoryVehiclesQueryParams = {
    locationId?: string;
    vehicleType?: VehicleType;
    manufacturer?: string;
    arrivalDateStartAt?: Date;
    arrivalDateEndAt?: Date;
    vin?: string;
    sortBy?: TableSort<VehicleInventoryTableColumn>;
    limit?: number;
};

export type GetOutgoingVehiclesQueryParams = {
    locationId?: string;
    vehicleType?: VehicleType;
    manufacturer?: string;
    expectedDepartureDateStartAt?: Date;
    expectedDepartureDateEndAt?: Date;
    vin?: string;
    sortBy?: TableSort<OutgoingVehiclesTableColumn>;
    limit?: number;
};

export type GetDepartedVehiclesQueryParams = {
    locationId?: string;
    vehicleType?: VehicleType;
    manufacturer?: string;
    arrivalDateStartAt?: Date;
    arrivalDateEndAt?: Date;
    departureDateStartAt?: Date;
    departureDateEndAt?: Date;
    vin?: string;
    sortBy?: TableSort<DepartedVehiclesTableColumn>;
    limit?: number;
};

export type PutArrivalCompletionRequestProps = {
    compoundVehicleId: string;
    arrivalStatusChanged: CompletionStatusChange;
};

export type PutDepartureCompletionRequestProps = {
    compoundVehicleId: string;
    departedStatusChange: CompletionStatusChange;
};

const IncomingVehiclesTag = 'IncomingVehiclesTag';
const InventoryVehiclesTag = 'InventoryVehiclesTag';
const OutgoingVehiclesTag = 'OutgoingVehiclesTag';
const DepartedVehiclesTag = 'DepartedVehiclesTag';

export const compoundManagementApi = createApi({
    reducerPath: 'compoundManagementApi',
    baseQuery: fetchBaseQuery({ baseUrl: config.backend.FINISHED_VEHICLES_SERVICE, prepareHeaders }),
    tagTypes: [IncomingVehiclesTag, InventoryVehiclesTag, OutgoingVehiclesTag, DepartedVehiclesTag],
    endpoints: (builder) => ({
        getIncomingVehicles: builder.query<IncomingVehiclesPage, GetIncomingVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_INCOMING_VEHICLES_PATH,
                params: mapToIncomingVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(IncomingVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(IncomingVehiclesPageCodec, mapToIncomingVehiclesPage),
            providesTags: [IncomingVehiclesTag],
        }),
        getInventoryVehicles: builder.query<InventoryVehiclesPage, GetInventoryVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_INVENTORY_VEHICLES_PATH,
                params: mapToInventoryVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(InventoryVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(InventoryVehiclesPageCodec, mapToInventoryVehiclesPage),
            providesTags: [InventoryVehiclesTag],
        }),
        getOutgoingVehicles: builder.query<OutgoingVehiclesPage, GetOutgoingVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_OUTGOING_VEHICLES_PATH,
                params: mapToOutgoingVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(OutgoingVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(OutgoingVehiclesPageCodec, mapToOutgoingVehiclesPage),
            providesTags: [OutgoingVehiclesTag],
        }),
        getDepartedVehicles: builder.query<DepartedVehiclesPage, GetDepartedVehiclesQueryParams>({
            query: (queryParameters) => ({
                url: COMPOUND_MANAGEMENT_DEPARTED_VEHICLES_PATH,
                params: mapToDepartedVehicleQueryParameters(queryParameters),
                validateStatus: validateResponse(DepartedVehiclesPageCodec),
            }),
            transformResponse: decodeResponse(DepartedVehiclesPageCodec, mapToDepartedVehiclesPage),
            providesTags: [DepartedVehiclesTag],
        }),
        putArrivalCompletion: builder.mutation<void, PutArrivalCompletionRequestProps>({
            query: ({ compoundVehicleId, arrivalStatusChanged }) => ({
                url: `${COMPOUND_MANAGEMENT_VEHICLES_PATH}/${compoundVehicleId}/${COMPOUND_MANAGEMENT_ARRIVAL_COMPLETIONS_PATH}`,
                method: 'PUT',
                body: mapToApiStatusCompletion(arrivalStatusChanged),
            }),
            invalidatesTags: [IncomingVehiclesTag, InventoryVehiclesTag, DepartedVehiclesTag],
        }),
        putDepartureCompletion: builder.mutation<void, PutDepartureCompletionRequestProps>({
            query: ({ compoundVehicleId, departedStatusChange }) => ({
                url: `${COMPOUND_MANAGEMENT_VEHICLES_PATH}/${compoundVehicleId}/${COMPOUND_MANAGEMENT_DEPARTURE_COMPLETIONS_PATH}`,
                method: 'PUT',
                body: mapToApiStatusCompletion(departedStatusChange),
            }),
            invalidatesTags: [DepartedVehiclesTag, OutgoingVehiclesTag],
        }),
    }),
});

export const {
    useGetIncomingVehiclesQuery,
    useGetInventoryVehiclesQuery,
    useGetOutgoingVehiclesQuery,
    useGetDepartedVehiclesQuery,
    usePutArrivalCompletionMutation,
    usePutDepartureCompletionMutation,
} = compoundManagementApi;

export const matchIncomingVehiclesReceived = compoundManagementApi.endpoints.getIncomingVehicles.matchFulfilled;
export const matchOutgoingVehiclesReceived = compoundManagementApi.endpoints.getOutgoingVehicles.matchFulfilled;
export const matchInventoryVehiclesReceived = compoundManagementApi.endpoints.getInventoryVehicles.matchFulfilled;
export const matchDepartedVehiclesReceived = compoundManagementApi.endpoints.getDepartedVehicles.matchFulfilled;
