import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types';
import { fromEnum } from '../../responseUtil';
import { VehicleCodec } from './vehicleCompound.types';
import { ApiVehicleType } from '../vehicleApi.types';
import { AddressCodec } from '../address.types';

export enum ApiIncomingVehicleSort {
    EXPECTED_ARRIVAL_AT_ASC = '+expected_arrival_at',
    EXPECTED_ARRIVAL_AT_DESC = '-expected_arrival_at',
    LOCATION_IDENTIFIER_ASC = '+location_identifier',
    LOCATION_IDENTIFIER_DESC = '-location_identifier',
    FREIGHT_FORWARDER_ASC = '+freight_forwarder',
    FREIGHT_FORWARDER_DESC = '-freight_forwarder',
}

export enum ApiInventoryVehicleSort {
    ARRIVAL_COMPLETED_AT_ASC = '+arrival_completed_at',
    ARRIVAL_COMPLETED_AT_DESC = '-arrival_completed_at',
    LOCATION_IDENTIFIER_ASC = '+location_identifier',
    LOCATION_IDENTIFIER_DESC = '-location_identifier',
    FREIGHT_FORWARDER_ASC = '+freight_forwarder',
    FREIGHT_FORWARDER_DESC = '-freight_forwarder',
}

export enum ApiOutgoingVehicleSort {
    EXPECTED_DEPARTURE_AT_ASC = '+expected_departure_at',
    EXPECTED_DEPARTURE_AT_DESC = '-expected_departure_at',
    LOCATION_IDENTIFIER_ASC = '+location_identifier',
    LOCATION_IDENTIFIER_DESC = '-location_identifier',
    FREIGHT_FORWARDER_ASC = '+freight_forwarder',
    FREIGHT_FORWARDER_DESC = '-freight_forwarder',
}

export enum ApiDepartedVehicleSort {
    ARRIVAL_COMPLETED_AT_ASC = '+arrival_completed_at',
    ARRIVAL_COMPLETED_AT_DESC = '-arrival_completed_at',
    DEPARTURE_COMPLETED_AT_ASC = '+departure_completed_at',
    DEPARTURE_COMPLETED_AT_DESC = '-departure_completed_at',
    LOCATION_IDENTIFIER_ASC = '+location_identifier',
    LOCATION_IDENTIFIER_DESC = '-location_identifier',
    ARRIVAL_FREIGHT_FORWARDER_ASC = '+arrival_freight_forwarder',
    ARRIVAL_FREIGHT_FORWARDER_DESC = '-arrival_freight_forwarder',
    DEPARTURE_FREIGHT_FORWARDER_ASC = '+departure_freight_forwarder',
    DEPARTURE_FREIGHT_FORWARDER_DESC = '-departure_freight_forwarder',
}

const IncomingVehicleQueryParameterCodec = t.partial({
    location_identifier: t.string,
    vehicle_type: fromEnum<ApiVehicleType>('ApiVehicleType', ApiVehicleType),
    manufacturer: t.string,
    expected_arrival_date_start_at: t.string,
    expected_arrival_date_end_at: t.string,
    vin: t.string,
    sort: fromEnum<ApiIncomingVehicleSort>('ApiIncomingVehicleSort', ApiIncomingVehicleSort),
    limit: t.string,
});

const OutgoingVehicleQueryParameterCodec = t.partial({
    location_identifier: t.string,
    vehicle_type: fromEnum<ApiVehicleType>('ApiVehicleType', ApiVehicleType),
    manufacturer: t.string,
    expected_departure_date_start_at: t.string,
    expected_departure_date_end_at: t.string,
    vin: t.string,
    sort: fromEnum<ApiOutgoingVehicleSort>('ApiOutgoingVehicleSort', ApiOutgoingVehicleSort),
    limit: t.string,
});

const DepartedVehicleQueryParameterCodec = t.partial({
    location_identifier: t.string,
    vehicle_type: fromEnum<ApiVehicleType>('ApiVehicleType', ApiVehicleType),
    manufacturer: t.string,
    arrival_date_start_at: t.string,
    arrival_date_end_at: t.string,
    departure_date_start_at: t.string,
    departure_date_end_at: t.string,
    vin: t.string,
    sort: fromEnum<ApiDepartedVehicleSort>('ApiDepartedVehicleSort', ApiDepartedVehicleSort),
    limit: t.string,
});

const InventoryVehicleQueryParameterCodec = t.partial({
    location_identifier: t.string,
    vehicle_type: fromEnum<ApiVehicleType>('ApiVehicleType', ApiVehicleType),
    manufacturer: t.string,
    arrival_date_start_at: t.string,
    arrival_date_end_at: t.string,
    vin: t.string,
    sort: fromEnum<ApiInventoryVehicleSort>('ApiInventoryVehicleSort', ApiInventoryVehicleSort),
    limit: t.string,
});

export enum ApiMeansOfTransportType {
    TRUCK = 'truck',
}

const MeansOfTransportTypeCodec = fromEnum<ApiMeansOfTransportType>('ApiMeansOfTransportType', ApiMeansOfTransportType);

const MeansOfTransportCodec = t.intersection([
    t.type({ type: MeansOfTransportTypeCodec }),
    t.partial({ id: t.string }),
]);

const CompoundLocationCodec = t.type({ identifier: NonEmptyString, address: AddressCodec, time_zone: NonEmptyString });

const FreightForwarderCodec = t.intersection([
    t.type({ name: NonEmptyString }),
    t.partial({ additional_name: NonEmptyString }),
]);

const ExpectedArrivalCodec = t.intersection([
    t.type({ expected_arrival_at: NonEmptyString, means_of_transport: MeansOfTransportCodec, is_cancelled: t.boolean }),
    t.partial({ freight_forwarder: FreightForwarderCodec }),
]);

const ArrivalCodec = t.intersection([
    t.type({ arrival_completed_at: NonEmptyString, is_cancelled: t.boolean }),
    t.partial({ freight_forwarder: FreightForwarderCodec }),
]);

const ExpectedDepartureCodec = t.intersection([
    t.type({
        expected_departure_at: NonEmptyString,
        means_of_transport: MeansOfTransportCodec,
        is_cancelled: t.boolean,
    }),
    t.partial({ freight_forwarder: FreightForwarderCodec }),
]);

const DepartureCodec = t.intersection([
    t.type({ departure_completed_at: NonEmptyString }),
    t.partial({ freight_forwarder: FreightForwarderCodec }),
]);

const CompoundVehicleCodec = t.type({
    id: NonEmptyString,
    compound_location: CompoundLocationCodec,
    vehicle: VehicleCodec,
});

const IncomingVehicleCodec = t.intersection([CompoundVehicleCodec, t.type({ arrival: ExpectedArrivalCodec })]);

const InventoryVehicleCodec = t.intersection([CompoundVehicleCodec, t.type({ arrival: ArrivalCodec })]);

const OutgoingVehicleCodec = t.intersection([CompoundVehicleCodec, t.type({ departure: ExpectedDepartureCodec })]);

const DepartedVehicleCodec = t.intersection([
    CompoundVehicleCodec,
    t.type({ arrival: ArrivalCodec, departure: DepartureCodec }),
]);

export const IncomingVehiclesPageCodec = t.type({
    items: t.array(IncomingVehicleCodec),
    has_more: t.boolean,
});

export const InventoryVehiclesPageCodec = t.type({
    items: t.array(InventoryVehicleCodec),
    has_more: t.boolean,
});

export const OutgoingVehiclesPageCodec = t.type({
    items: t.array(OutgoingVehicleCodec),
    has_more: t.boolean,
});

export const DepartedVehiclesPageCodec = t.type({
    items: t.array(DepartedVehicleCodec),
    has_more: t.boolean,
});

export type ApiIncomingVehicleQueryParameter = t.TypeOf<typeof IncomingVehicleQueryParameterCodec>;
export type ApiOutgoingVehicleQueryParameter = t.TypeOf<typeof OutgoingVehicleQueryParameterCodec>;
export type ApiInventoryVehicleQueryParameter = t.TypeOf<typeof InventoryVehicleQueryParameterCodec>;
export type ApiDepartedVehicleQueryParameter = t.TypeOf<typeof DepartedVehicleQueryParameterCodec>;

export type ApiCompoundLocation = t.TypeOf<typeof CompoundLocationCodec>;
export type ApiExpectedArrival = t.TypeOf<typeof ExpectedArrivalCodec>;
export type ApiArrival = t.TypeOf<typeof ArrivalCodec>;
export type ApiExpectedDeparture = t.TypeOf<typeof ExpectedDepartureCodec>;
export type ApiDeparture = t.TypeOf<typeof DepartureCodec>;
export type ApiFreightForwarder = t.TypeOf<typeof FreightForwarderCodec>;
export type ApiMeansOfTransport = t.TypeOf<typeof MeansOfTransportCodec>;
export type ApiIncomingVehicle = t.TypeOf<typeof IncomingVehicleCodec>;
export type ApiInventoryVehicle = t.TypeOf<typeof InventoryVehicleCodec>;
export type ApiOutgoingVehicle = t.TypeOf<typeof OutgoingVehicleCodec>;
export type ApiDepartedVehicle = t.TypeOf<typeof DepartedVehicleCodec>;

export type ApiIncomingVehiclesPage = t.TypeOf<typeof IncomingVehiclesPageCodec>;
export type ApiInventoryVehiclesPage = t.TypeOf<typeof InventoryVehiclesPageCodec>;
export type ApiOutgoingVehiclesPage = t.TypeOf<typeof OutgoingVehiclesPageCodec>;
export type ApiDepartedVehiclesPage = t.TypeOf<typeof DepartedVehiclesPageCodec>;

export type ApiCompletionStatusChange = {
    status_at: string;
};
