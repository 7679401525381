import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';
import { CompoundManagementView } from '../compoundManagement.types';
import classNames from 'classnames';

type CompoundManagementViewSelectionProps = {
    activeView: CompoundManagementView;
    setActiveView: (newView: CompoundManagementView) => void;
};

export const CompoundManagementViewSelection = ({
    activeView,
    setActiveView,
}: CompoundManagementViewSelectionProps) => (
    <div className="btn-group" data-testid="toolbar-button-group">
        {[
            { view: CompoundManagementView.INCOMING_VEHICLES, icon: 'rioglyph-load' },
            { view: CompoundManagementView.INVENTORY, icon: 'rioglyph-car' },
            { view: CompoundManagementView.OUTGOING_VEHICLES, icon: 'rioglyph-load-unload' },
            { view: CompoundManagementView.DEPARTED, icon: 'rioglyph-folder-closed' },
        ].map(({ view, icon }) => (
            <Button key={view} active={activeView === view} onClick={() => setActiveView(view)}>
                <span className={classNames('rioglyph', icon)} />
                <FormattedMessage id={view} />
            </Button>
        ))}
    </div>
);
