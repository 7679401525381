/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';
import type { Breadcrumb } from '@sentry/types';

if (import.meta.env.MODE === 'production') {
    const release = import.meta.env.VITE_COMMIT_HASH || import.meta.env.APP_VERSION;

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        environment: 'production',
        release,
        beforeSend: (event, hint) => {
            if (
                isTypeError('Failed to fetch', hint.originalException) ||
                isTypeError('Load failed', hint.originalException) ||
                isTypeError('NetworkError', hint.originalException) ||
                isUnhandledRejectionOfNonObject(event)
            ) {
                // drop event
                return null;
            } else {
                return event;
            }
        },
    });
}

export const reportErrorToSentry = (exception: unknown, hint?: Parameters<typeof Sentry.captureException>[1]) => {
    if (exception === undefined) {
        Sentry.captureMessage('reportErrorToSentry received undefined');
    } else {
        Sentry.captureException(exception, hint);
    }
};

export const addBreadcrumbToSentry = (breadcrumb: Breadcrumb) => {
    Sentry.addBreadcrumb(breadcrumb);
};

const isTypeError = (errorMessagePrefix: string, error: any) =>
    (error instanceof Error && error.name === 'TypeError' && error.message.startsWith(errorMessagePrefix)) ||
    (typeof error === 'object' &&
        typeof error?.error === 'string' &&
        error.error.includes(`TypeError: ${errorMessagePrefix}`));

const isUnhandledRejectionOfNonObject = (event: Sentry.ErrorEvent): boolean => {
    /* Outlook link scanning can lead to rejected promises with string
     * error instead of a proper Error object. See also
     * https://github.com/getsentry/sentry-javascript/issues/3440 */
    const pattern = /Non-Error promise rejection captured with value: Object Not Found Matching Id/;
    if (event?.exception?.values?.length === 1) {
        const firstException = event.exception.values[0];
        return Boolean(
            firstException.type === 'UnhandledRejection' && firstException.value && pattern.test(firstException.value),
        );
    } else {
        return false;
    }
};
