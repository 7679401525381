import classNames from 'classnames';
import { ReactNode } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../configuration/setup/hooks';
import { compoundManagementSlice, getSelectedCompoundVehicle } from '../../reducers/compoundManagementSlice';
import { ColumnDefinition } from '../../sharedComponents/table/SortedTableHeader';
import { CompoundVehicle } from '../compoundManagement.types';

type CompoundManagementTableRowProps<TableColumnEnum extends string> = {
    vehicle: CompoundVehicle;
    highlight?: 'danger' | 'warning' | undefined;
    columnDefinition: ColumnDefinition<TableColumnEnum>[];
    children: (definition: TableColumnEnum) => ReactNode;
};

export const CompoundManagementTableRow = <TableColumnEnum extends string>({
    vehicle,
    highlight = undefined,
    columnDefinition,
    children: renderRow,
}: CompoundManagementTableRowProps<TableColumnEnum>) => {
    const dispatch = useAppDispatch();
    const selectedCompoundVehicle = useAppSelector(getSelectedCompoundVehicle);
    const isRowSelected = vehicle.id === selectedCompoundVehicle?.id;

    const handleRowClick = () => {
        if (isRowSelected) {
            dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
        } else {
            dispatch(compoundManagementSlice.actions.selectCompoundVehicle(vehicle));
        }
    };

    return (
        <tr className={classNames('cursor-pointer', { active: isRowSelected }, highlight)} onClick={handleRowClick}>
            {columnDefinition.map((def) => {
                return <td key={def.column}>{renderRow(def.column)}</td>;
            })}
        </tr>
    );
};
