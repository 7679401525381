import AutoSuggest, { AutoSuggestSuggestion, SelectedSuggestion } from '@rio-cloud/rio-uikit/AutoSuggest';
import noop from 'lodash/noop';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getUserProfile } from '../../../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../../../configuration/setup/hooks';
import { useGetAssetsQuery } from '../../../../api/assetsApi';
import { AssetStatus, AssetType } from '../../../../api/types/assetsApi.types';
import { Asset } from '../../../assets.types';
import { MeansOfTransport } from '../../../transportAssignment.types';

type AutoSuggestAssetInputProps = {
    meansOfTransport: MeansOfTransport | undefined;
    setMeansOfTransport: (value: { id?: string; assetId?: string }) => void;
    inputProps: {
        placeholder: string;
        value: string;
        disabled: boolean;
    };
};

type AssetSuggestion = AutoSuggestSuggestion & Asset;

export const AutoSuggestAssetInput = (props: AutoSuggestAssetInputProps) => {
    const userProfile = useAppSelector(getUserProfile);
    const { data: assets } = useGetAssetsQuery(
        {
            accountId: userProfile?.account!!,
            status: AssetStatus.ACTIVE,
            type: AssetType.TRUCK,
        },
        { skip: userProfile?.account === undefined },
    );
    const assetsWithLicensePlate: Asset[] = useMemo(
        () => assets?.filter((asset: Asset) => asset.licensePlate !== undefined) ?? [],
        [assets],
    );
    const [userInput, setUserInput] = useState('');

    const suggestions: AssetSuggestion[] = useMemo(() => {
        const normalizedUserInput = userInput.trim().toLowerCase();

        return !Boolean(normalizedUserInput)
            ? assetsWithLicensePlate
            : assetsWithLicensePlate.filter((suggestion) =>
                  suggestion.licensePlate!.toLowerCase().includes(normalizedUserInput.toLowerCase()),
              );
    }, [userInput, assetsWithLicensePlate]);

    const renderSuggestion = (suggestion: AutoSuggestSuggestion) => (
        <span>{(suggestion as AssetSuggestion).licensePlate}</span>
    );

    const handleSelectedSuggestions = (
        _event: React.MouseEvent<HTMLLIElement> | React.KeyboardEvent<HTMLDivElement>,
        selected: SelectedSuggestion,
    ) => {
        props.setMeansOfTransport({
            id: (selected.suggestion as AssetSuggestion).licensePlate,
            assetId: (selected.suggestion as AssetSuggestion).id,
        });
    };

    const getAssetByMeansOfTransportId = (meansOfTransportId: string): Asset | undefined =>
        assetsWithLicensePlate?.find((asset) => asset.licensePlate === meansOfTransportId);

    return (
        <AutoSuggest
            customSuggestionKey="licensePlate"
            getSuggestionValue={(suggestion) => suggestion.licensePlate as string}
            suggestions={suggestions}
            noItemMessage={<FormattedMessage id="outboundPortal.transportAssignments.sidebar.noAvailableAssets.info" />}
            onSuggestionsFetchRequested={noop}
            onSuggestionSelected={handleSelectedSuggestions}
            renderSuggestion={renderSuggestion}
            openOnFocus
            inputProps={{
                ...props.inputProps,
                onClear: () => {},
                onChange: (changeEvent, changeProps) => {
                    props.setMeansOfTransport({
                        id: changeProps.newValue,
                        assetId: getAssetByMeansOfTransportId(changeProps.newValue)?.id,
                    });
                    /* Filter suggestions by user input only after the first actual user input.
                       'onSuggestionsFetchRequested' is called already with the previous value,
                       before any user interaction. */
                    setUserInput(changeProps.newValue);
                },
                onBlur: () => {
                    props.setMeansOfTransport({
                        id: props.meansOfTransport?.id?.trim() ?? '',
                        assetId: props.meansOfTransport?.assetId,
                    });
                },
            }}
        />
    );
};
