import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { transportAssignmentsSlice } from '../reducers/transportAssignmentsSlice';
import { useAppDispatch } from '../../../configuration/setup/hooks';
import { serviceOrdersSlice } from '../reducers/serviceOrdersSlice';
import { compoundManagementSlice } from '../reducers/compoundManagementSlice';

export const RouteUpdater = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Implement deep linking or react to window location change here
        // console.log(location);

        // close sidebar and reset filters and sorting when navigating
        dispatch(transportAssignmentsSlice.actions.setInitialState());
        dispatch(serviceOrdersSlice.actions.setInitialState());
        dispatch(compoundManagementSlice.actions.setInitialState());
    }, [dispatch, location]);

    return null;
};
