import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Address } from './common.types';
import { NoValue } from './NoValue';

type AddressDetailsProps = {
    readonly address: Partial<Address>;
    readonly identifier?: string;
};

export const AddressDetails = ({ address, identifier }: AddressDetailsProps) => {
    const isEmptyAddress = Object.values(address).every(isNil);
    return isEmptyAddress ? (
        <NoValue />
    ) : (
        <div>
            <div className={classNames('text-medium', { 'margin-bottom-10': !identifier })}>{address.name}</div>
            {identifier && <div className="text-size-14 margin-bottom-10">{identifier}</div>}
            <div>{address.street}</div>
            <div>
                <span className="margin-right-5">{address.postalCode}</span>
                <span>{address.city}</span>
            </div>
            <div>{address.countryCode}</div>
        </div>
    );
};
