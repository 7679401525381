import { FormattedMessage } from 'react-intl';
import { assertExhaustive } from '../../../../../utils';
import { MeansOfTransportType } from '../../../transportAssignment/transportAssignment.types';
import { FunctionComponent } from 'react';

export const DisplayMeansOfTransportType: FunctionComponent<{
    meansOfTransportType: MeansOfTransportType;
}> = ({ meansOfTransportType }) => {
    switch (meansOfTransportType) {
        case MeansOfTransportType.TRUCK:
            return <FormattedMessage id="outboundOrderBook.common.meansOfTransportType.truck" />;
        default:
            assertExhaustive(meansOfTransportType);
    }
};
