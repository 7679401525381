import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { AddressDetails } from '../../../sharedComponents/AddressDetails';
import { Address } from '../../../sharedComponents/common.types';
import { DateTimeField } from '../../TransportAssignmentBaseComponents';
import { Contact, Loading, TransportCapacityOrderUnloading, Unloading } from '../../transportAssignment.types';
import { ContactDetails } from './AddressAndContactDetails';
import { NoValue } from '../../../sharedComponents/NoValue';

type PickUpDetailsRowProps = {
    readonly loading: Loading;
    readonly footer: ReactNode;
};

export const PickUpDetailsRow = ({ loading, footer }: PickUpDetailsRowProps) => {
    const dateLabelId =
        loading.confirmedPickUpDate !== undefined
            ? 'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedPickUpDate'
            : 'outboundPortal.transportAssignments.sidebar.assignmentInformation.requestedPickUpDateInterval';
    const dateNode = (
        <DateTimeField date={loading.confirmedPickUpDate ?? loading.requestedPickUpDate} timeZone={loading.timeZone} />
    );

    return (
        <TransportDetailRow
            dateLabelId={dateLabelId}
            address={loading.addressAndContact.address}
            date={dateNode}
            footerContent={footer}
            contact={loading.addressAndContact.contact}
            dateIcon="rioglyph-load-unload"
            rowHeading="outboundPortal.transportAssignments.table.from"
        />
    );
};

type DeliveryDetailsRowProps = {
    readonly unloading: Unloading;
    readonly footer: ReactNode;
};

export const DeliveryDetailsRow = ({ unloading, footer }: DeliveryDetailsRowProps) => {
    const dateLabelId =
        unloading.confirmedDeliveryDate !== undefined
            ? 'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedDeliveryDate'
            : 'outboundPortal.transportAssignments.sidebar.assignmentInformation.requestedDeliveryDate';
    const dateNode = (
        <DateTimeField
            date={unloading.confirmedDeliveryDate ?? unloading.requestedDeliveryDate}
            timeZone={unloading.timeZone}
        />
    );

    return (
        <TransportDetailRow
            dateLabelId={dateLabelId}
            address={unloading.addressAndContact?.address}
            date={dateNode}
            footerContent={footer}
            contact={unloading.addressAndContact?.contact}
            dateIcon="rioglyph-load"
            rowHeading="outboundPortal.transportAssignments.table.to"
        />
    );
};

type CapacityDeliveryDetailsRowProps = {
    readonly unloading: TransportCapacityOrderUnloading;
};

export const CapacityDeliveryDetailsRow = ({ unloading }: CapacityDeliveryDetailsRowProps) => {
    const dateLabelId =
        unloading.confirmedDeliveryDate !== undefined
            ? 'outboundPortal.transportAssignments.sidebar.assignmentInformation.confirmedDeliveryDate'
            : 'outboundPortal.transportAssignments.sidebar.assignmentInformation.requestedDeliveryDate';

    const deliveryDate = unloading.confirmedDeliveryDate ?? unloading.requestedDeliveryDate;

    const dateNode = deliveryDate ? <DateTimeField date={deliveryDate} timeZone={unloading.timeZone} /> : <NoValue />;

    return (
        <TransportDetailRow
            dateLabelId={dateLabelId}
            address={unloading.addressAndContact?.address}
            date={dateNode}
            contact={unloading.addressAndContact?.contact}
            dateIcon="rioglyph-load"
            rowHeading="outboundPortal.transportAssignments.table.to"
        />
    );
};

type TransportDetailRowProps = {
    readonly dateLabelId: string;
    readonly address?: Address;
    readonly date: ReactNode;
    readonly footerContent?: ReactNode;
    readonly contact?: Contact;
    readonly dateIcon: string;
    readonly rowHeading: string;
};

export const TransportDetailRow = ({
    dateLabelId,
    address,
    date,
    footerContent,
    contact,
    dateIcon,
    rowHeading,
}: TransportDetailRowProps) => {
    const footer =
        footerContent !== undefined ? (
            <>
                <hr className="margin-top-10 margin-bottom-10" />
                <div>{footerContent}</div>
            </>
        ) : undefined;

    return (
        <div className="display-flex border rounded">
            <div
                className={classNames(
                    'min-width-60',
                    'bg-lightest',
                    'text-center',
                    'vertical-top',
                    'padding-10',
                    address ? '' : 'text-color-gray',
                )}
            >
                <FormattedMessage id={rowHeading} />
            </div>
            <div className={classNames('flex-1-1-0', 'padding-10', address ? '' : 'bg-lightest')}>
                <div className="gap-10">
                    <div className="width-50pct pull-left">
                        {/* This does not cover the case when the user is not allowed to provide
                            an address (hasSelectableUnloadingAddress: false) but the address is still missing */}
                        {address ? (
                            <AddressDetails address={address} />
                        ) : (
                            <div className="text-color-gray">
                                <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.missingDeliveryAddress" />
                            </div>
                        )}
                        {contact && <ContactDetails contact={contact} />}
                    </div>
                    <div className="width-50pct pull-right">
                        <div className="margin-bottom-10 ellipsis-1">
                            <span className={classNames(dateIcon, 'margin-right-5', 'rioglyph')} aria-hidden="true" />
                            <span className="margin-bottom-10">
                                <FormattedMessage id={dateLabelId} />
                            </span>
                        </div>
                        {date}
                    </div>
                    <div className="clearfix" />
                    {footer}
                </div>
            </div>
        </div>
    );
};
