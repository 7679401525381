import { Control, Controller } from 'react-hook-form';
import { InterfaceAccessRequestedStatus, RegistrationInputs } from '../registration.types';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import RadioButton from '@rio-cloud/rio-uikit/RadioButton';

export const InterfaceSelection = (props: { control: Control<RegistrationInputs> }) => {
    const defaultValue: InterfaceAccessRequestedStatus = 'web';
    const elementName: keyof RegistrationInputs = 'interfaceAccessRequested';
    return (
        <div>
            <label className="control-label">
                <FormattedMessage id="outboundPortal.activation.form.interfaceAccess" />
            </label>
            <div className="display-flex gap-5">
                <InterfaceSelectionRadioButton
                    value="web"
                    elementName={elementName}
                    defaultValue={defaultValue}
                    control={props.control}
                    header={<FormattedMessage id="outboundPortal.activation.form.interfaceAccess.webPortal.header" />}
                    description={
                        <FormattedMessage id="outboundPortal.activation.form.interfaceAccess.webPortal.description" />
                    }
                />
                <InterfaceSelectionRadioButton
                    value="interface"
                    elementName={elementName}
                    defaultValue={defaultValue}
                    control={props.control}
                    header={<FormattedMessage id="outboundPortal.activation.form.interfaceAccess.interface.header" />}
                    description={
                        <FormattedMessage id="outboundPortal.activation.form.interfaceAccess.interface.descriptio" />
                    }
                />
            </div>
        </div>
    );
};
const InterfaceSelectionRadioButton = (props: {
    value: InterfaceAccessRequestedStatus;
    header: ReactElement;
    description: ReactElement;
    control: Control<RegistrationInputs>;
    defaultValue: InterfaceAccessRequestedStatus;
    elementName: keyof RegistrationInputs;
}) => {
    const getRadioClasses = (isSelected: boolean) =>
        `padding-15 border rounded ${isSelected ? 'border-color-highlight' : ''}`;

    return (
        <Controller
            control={props.control}
            name={props.elementName}
            rules={{ required: true }}
            defaultValue={props.defaultValue}
            render={({ field: { onChange, value } }) => (
                <RadioButton
                    value={props.value}
                    custom
                    className={classNames('flex-basis-100pct bg-white', getRadioClasses(value === props.value))}
                    onChange={onChange}
                    checked={value === props.value}
                >
                    <div className="radio-text-wrapper display-flex">
                        <div className="radio-text" />
                        <div>
                            <div className="text-medium">{props.header}</div>
                            <div className="text-color-dark">{props.description}</div>
                        </div>
                    </div>
                </RadioButton>
            )}
        />
    );
};
