import { NoValue } from '../../../sharedComponents/NoValue';
import { FreightForwarder } from '../../compoundManagement.types';

export const DisplayFreightForwarder = ({
    freightForwarder,
    className,
}: {
    freightForwarder: FreightForwarder | undefined;
    className?: string;
}) => {
    return freightForwarder === undefined ? (
        <NoValue />
    ) : (
        <span className={className}>
            {freightForwarder.name} {freightForwarder.additionalName}
        </span>
    );
};
