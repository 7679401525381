import { TransportAssignment } from '../transportAssignment.types';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { usePostArchiveTransportAssignmentMutation } from '../../api/transportOrderApi';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { getActionButtonConfig } from '../buttonStyleAndSettings';
import { transportAssignmentsSlice } from '../../reducers/transportAssignmentsSlice';
import { getTransportAssignmentIds } from '../TransportAssignmentUtils';
import { TableActionButton } from '../../sharedComponents/table/TableActionButton';
import { runInBackground } from '../../../../configuration/setup/backgroundActions';

export const TransportAssignmentsTableActionButton = (props: { transportAssignment: TransportAssignment }) => {
    const { status, isArchived, type } = props.transportAssignment;
    const dispatch = useAppDispatch();
    const [archiveTransportAssignment, archiveRequest] = usePostArchiveTransportAssignmentMutation();

    const actionButtonConfig = getActionButtonConfig(status, type, isArchived, props.transportAssignment.isFinalized);
    const isLoading = archiveRequest.isLoading;

    const handleArchiveTransportAssignment = async () => {
        await archiveTransportAssignment({
            transportOrderIds: getTransportAssignmentIds(props.transportAssignment),
            isArchived: true,
        })
            .unwrap()
            .then(() => showSuccessNotification('outboundPortal.notification.archived.success'))
            .catch(handleQueryError);
    };

    const handleOnClick = () => {
        if (actionButtonConfig && actionButtonConfig.expandSidebar) {
            dispatch(transportAssignmentsSlice.actions.selectTransportAssignment(props.transportAssignment));
        } else {
            runInBackground(handleArchiveTransportAssignment);
        }
    };

    if (!actionButtonConfig) {
        return <> </>;
    }

    return <TableActionButton actionButtonConfig={actionButtonConfig} isLoading={isLoading} onClick={handleOnClick} />;
};
