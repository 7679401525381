import { FunctionComponent } from 'react';
import { AddressDetails } from '../../sharedComponents/AddressDetails';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, Vehicle } from '../compoundManagement.types';

type CompoundLocationAndDestinationDetailsProps = {
    compoundLocation: CompoundLocation;
    vehicle: Vehicle;
};

export const CompoundLocationAndDestinationDetails: FunctionComponent<CompoundLocationAndDestinationDetailsProps> = ({
    compoundLocation,
    vehicle,
}) => {
    const rowClasses = 'display-flex gap-25 margin-top-20 margin-left-25 align-items-start';

    return (
        <div className={rowClasses}>
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.location">
                <AddressDetails address={compoundLocation.address} identifier={compoundLocation.identifier} />
            </LabelledInfoCell>
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.finalDestination">
                <AddressDetails
                    address={vehicle.finalDestination?.address ?? {}}
                    identifier={vehicle.finalDestination?.identifier}
                />
            </LabelledInfoCell>
        </div>
    );
};
