import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserSessionExpired } from '../../../../configuration/login/loginSlice';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { useGetIncomingVehiclesQuery } from '../../api/compoundManagementApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { getIncomingVehicleSorting, getVinSearchFilter } from '../../reducers/compoundManagementSlice';
import { LoadMoreButton } from '../../sharedComponents/table/LoadMoreButton';
import { useSetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { CompoundManagementToolbar } from '../toolbar/CompoundManagementToolbar';
import { IncomingVehiclesTable } from './table/IncomingVehiclesTable';

export const DEFAULT_COMPOUND_INCOMING_LIMIT_SIZE = 100;
const POLLING_INTERVAL = 60000;

export const IncomingVehicles: FunctionComponent = () => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const vinSearch = useAppSelector(getVinSearchFilter);
    const sorting = useAppSelector(getIncomingVehicleSorting);
    const [limit, setLimit] = useState(DEFAULT_COMPOUND_INCOMING_LIMIT_SIZE);

    const {
        data: incomingPage,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetIncomingVehiclesQuery(
        {
            vin: vinSearch.length === 0 ? undefined : vinSearch,
            limit,
            sortBy: sorting,
        },
        {
            pollingInterval: isSessionExpired ? undefined : POLLING_INTERVAL,
        },
    );

    useSetUserTriggeredFetching({
        isFetching,
        depsWhichTriggerUserFetching: [],
    });

    if (isError) {
        console.error(error);
        handleQueryError(error);
    }

    if (isLoading) {
        return (
            <div data-testid="spinner">
                <Spinner />
            </div>
        );
    }

    if (incomingPage === undefined) {
        return (
            <ErrorState
                headline={<FormattedMessage id="intl-msg:common-message.error.generic.headline" />}
                message={<FormattedMessage id="intl-msg:common-message.error.generic.message" />}
            />
        );
    }

    const incomingVehicles = incomingPage.items;
    return (
        <>
            <CompoundManagementToolbar />
            <IncomingVehiclesTable vehicles={incomingVehicles} />
            {incomingVehicles.length > 0 && (
                <LoadMoreButton
                    onLoadMore={() => setLimit(limit + DEFAULT_COMPOUND_INCOMING_LIMIT_SIZE)}
                    hasMoreToLoad={incomingPage.hasMore}
                />
            )}
        </>
    );
};
