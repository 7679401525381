import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { compoundManagementSlice, getOutgoingVehicleSorting } from '../../../reducers/compoundManagementSlice';
import { AppTableCardsSorting } from '../../../sharedComponents/table/AppTableCardsSorting';
import { TableSort } from '../../../sharedComponents/table/SortedTableHeader';
import { OutgoingVehicle } from '../../compoundManagement.types';
import { Table } from '../../shared/table/Table';
import { CompoundManagementTableRow } from '../../table/CompoundManagementTableRow';
import { outgoingVehiclesColumnDefinition, OutgoingVehiclesTableColumn } from './OutgoingVehiclesTableColumn';
import { OutgoingVehiclesTableDataContent } from './OutgoingVehiclesTableDataContent';

type OutgoingVehiclesTableProps = {
    readonly vehicles: readonly OutgoingVehicle[];
};

export const OutgoingVehiclesTable = ({ vehicles }: OutgoingVehiclesTableProps) => {
    const sorting = useAppSelector(getOutgoingVehicleSorting);
    const dispatch = useAppDispatch();

    const setSorting = (newSorting: TableSort<OutgoingVehiclesTableColumn>) =>
        dispatch(compoundManagementSlice.actions.setOutgoingSorting(newSorting));

    return (
        <>
            <AppTableCardsSorting
                columnDefinition={outgoingVehiclesColumnDefinition}
                sorting={sorting}
                setSorting={setSorting}
            />
            <Table
                columnDefinition={outgoingVehiclesColumnDefinition}
                sorting={sorting}
                setSorting={setSorting}
                items={vehicles}
                notFoundMessage={
                    <FormattedMessage id="outboundOrderBook.compoundManagement.outgoingVehicles.notFound" />
                }
            >
                {vehicles.map((vehicle: OutgoingVehicle) => (
                    <CompoundManagementTableRow
                        key={vehicle.id}
                        highlight={vehicle.departure.isCancelled ? 'danger' : undefined}
                        columnDefinition={outgoingVehiclesColumnDefinition}
                        vehicle={vehicle}
                    >
                        {(column) => <OutgoingVehiclesTableDataContent column={column} vehicle={vehicle} />}
                    </CompoundManagementTableRow>
                ))}
            </Table>
        </>
    );
};
