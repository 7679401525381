import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { showSuccessNotification } from '../../../notifications/SuccessNotification';
import { compoundManagementSlice } from '../../../reducers/compoundManagementSlice';
import { NoValue } from '../../../sharedComponents/NoValue';
import { LabelledInfoCell } from '../../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, ExpectedArrival } from '../../compoundManagement.types';
import { DateTimeInfo } from '../../shared/dataDisplay/DateTimeInfo';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayMeansOfTransportType } from '../../shared/dataDisplay/DisplayMeansOfTransportType';
import { CompletedStatusAtInput } from '../../shared/sidebar/CompletedStatusAtInput';
import { getStatusDateValidationMessageId } from '../../shared/sidebar/validateStatusDate';
import { usePutArrivalCompletionMutation } from '../../../api/compoundManagementApi';
import { handleQueryError } from '../../../notifications/ErrorNotification';
import { runInBackgroundCallback } from '../../../../../configuration/setup/backgroundActions';

export type ExpectedArrivalDetailsProps = {
    compoundVehicleId: string;
    arrival: ExpectedArrival;
    compoundLocation: CompoundLocation;
};

export const ExpectedArrivalDetails: FunctionComponent<ExpectedArrivalDetailsProps> = ({
    compoundVehicleId,
    arrival,
    compoundLocation,
}) => {
    const dispatch = useAppDispatch();
    const [completeArrival, completeArrivalRequest] = usePutArrivalCompletionMutation();

    const rowClasses = 'display-flex gap-25 align-items-start';
    const expectedArrivalAt = arrival.expectedArrivalAt;
    const isCancelled = arrival.isCancelled;

    const [submitted, setSubmitted] = useState(false);
    const [arrivalCompletedAt, setArrivalCompletedAt] = useState<Date | string | undefined>(undefined);

    const handleConfirmArrival = runInBackgroundCallback(async () => {
        setSubmitted(true);

        const isStatusValid = getStatusDateValidationMessageId(arrivalCompletedAt) === undefined;

        if (isStatusValid) {
            try {
                await completeArrival({
                    compoundVehicleId,
                    arrivalStatusChanged: {
                        statusAt: arrivalCompletedAt!! as Date,
                    },
                }).unwrap();

                showSuccessNotification(
                    'outboundOrderBook.compoundManagement.notification.incomingVehicle.confirmArrival.success',
                );
                dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
            } catch (error: unknown) {
                handleQueryError(error);
            }
        }
    });

    return (
        <>
            <div className={classNames(rowClasses, 'margin-bottom-15')}>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.expectedArrivalDate">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DateTimeInfo date={expectedArrivalAt} timezone={compoundLocation?.timeZone} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.carrierAndTransportType">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DisplayFreightForwarder freightForwarder={arrival.freightForwarder} />
                        {' / '}
                        <DisplayMeansOfTransportType meansOfTransportType={arrival.meansOfTransport.type} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </div>
            <div className={rowClasses}>
                <CompletedStatusAtInput
                    labelId="outboundOrderBook.compoundManagement.table.heading.arrivalDate"
                    timeZone={compoundLocation.timeZone}
                    disabled={isCancelled}
                    statusDate={arrivalCompletedAt}
                    onChangeStatusDate={setArrivalCompletedAt}
                    validationErrorId={submitted ? getStatusDateValidationMessageId(arrivalCompletedAt) : undefined}
                />

                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.meansOfTransport">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        {arrival.meansOfTransport.id ?? <NoValue />}
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </div>
            {!isCancelled && (
                <>
                    <hr />
                    <div className="display-flex gap-25 justify-content-end">
                        <ConfirmButton isLoading={completeArrivalRequest.isLoading} onClick={handleConfirmArrival} />
                    </div>
                </>
            )}
        </>
    );
};

const ConfirmButton: FunctionComponent<{ isLoading: boolean; onClick: () => void }> = ({ isLoading, onClick }) => (
    <Button
        className={classNames(isLoading ? 'btn-loading' : '')}
        disabled={isLoading}
        iconName="rioglyph-load"
        bsStyle={Button.PRIMARY}
        onClick={onClick}
    >
        <FormattedMessage id="outboundPortal.compoundManagement.button.confirmArrival" />
    </Button>
);
