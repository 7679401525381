import { FormattedMessage } from 'react-intl';
import {
    useGetTermsAndConditionsAgreementsQuery,
    usePostTermsAndConditionsAgreementMutation,
} from '../api/activationApi';
import React, { ReactElement, useState } from 'react';
import Checkbox from '@rio-cloud/rio-uikit/Checkbox';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getUserProfile, isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { handleQueryError } from '../notifications/ErrorNotification';
import { showSuccessNotification } from '../notifications/SuccessNotification';
import { ConfirmButtonText } from '../sharedComponents/ConfirmButtonText';
import { runInBackgroundCallback } from '../../../configuration/setup/backgroundActions';

// eslint-disable-next-line max-lines-per-function
export const TermsAndConditionsModal = (): React.ReactElement | null => {
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const userLanguage = useAppSelector(getUserProfile)?.locale ?? 'en';
    const languageForLinks = userLanguage.substring(0, 2);
    const {
        data: termsAndConditionsAgreement,
        isLoading,
        isFetching,
        isError,
        error,
    } = useGetTermsAndConditionsAgreementsQuery(undefined, {
        pollingInterval: isSessionExpired ? undefined : 86400000, // evey 24 hours
    });
    const [acceptTermsAndConditions, request] = usePostTermsAndConditionsAgreementMutation();

    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

    const userHasAgreed = (): boolean => {
        if (isLoading) {
            return true; // Optimistic
        }
        return termsAndConditionsAgreement?.accepted === true;
    };

    const handleAcceptTermsAndConditions = runInBackgroundCallback(async () => {
        await acceptTermsAndConditions()
            .unwrap()
            .then(() => showSuccessNotification('outboundPortal.notification.termsAndConditions.success'))
            .catch(handleQueryError);
    });

    if (isError) {
        handleQueryError(error);
        return null;
    }

    if (userHasAgreed()) {
        return null;
    }

    const renderDialogContent = (): ReactElement => {
        return (
            <div className="display-flex flex-column">
                <p>
                    <FormattedMessage id="outboundPortal.termsAndConditions.agreement.dialog.text" />
                </p>
                <div className="display-flex flex-row">
                    <Checkbox
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            setTermsAndConditionsAccepted(event.target.checked)
                        }
                    >
                        <FormattedMessage
                            id="outboundPortal.termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel"
                            values={{
                                _linkTermsConditions: (
                                    <a
                                        href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/tos/v06072023`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FormattedMessage id="outboundPortal.termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkTermsConditions.text" />
                                    </a>
                                ),
                                _linkSpecifications: (
                                    <a
                                        href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/dos/v06072023`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FormattedMessage id="outboundPortal.termsAndConditions.agreement.dialog.checkTermsAndConditionsLabel.linkSpecifications.text" />
                                    </a>
                                ),
                            }}
                        />
                    </Checkbox>
                </div>
                <div className="display-flex flex-row">
                    <Checkbox
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            setPrivacyPolicyChecked(event.target.checked)
                        }
                    >
                        <FormattedMessage
                            id="outboundPortal.termsAndConditions.agreement.dialog.checkPrivacyPolicyLabel"
                            values={{
                                _link: (
                                    <a
                                        href={`https://rio.cloud/${languageForLinks}/documents/outbound-order-book/privacy-policy`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FormattedMessage id="outboundPortal.termsAndConditions.agreement.dialog.checkPrivacyPolicyLabel.link.text" />
                                    </a>
                                ),
                            }}
                        />
                    </Checkbox>
                </div>
            </div>
        );
    };

    return (
        <ConfirmationDialog
            show={!userHasAgreed()}
            content={renderDialogContent()}
            title={<FormattedMessage id="outboundPortal.termsAndConditions.agreement.dialog.title" />}
            useOverflow={false}
            cancelButtonText={<FormattedMessage id="outboundPortal.termsAndConditions.agreement.dialog.cancel" />}
            confirmButtonText={
                <ConfirmButtonText
                    showSpinner={isFetching || request.isLoading}
                    labelId="outboundPortal.termsAndConditions.agreement.dialog.confirm"
                />
            }
            disableConfirm={
                !termsAndConditionsAccepted || !privacyPolicyChecked || isLoading || isFetching || request.isLoading
            }
            onClickCancel={(): void => {
                window.location.href = `https://rio.cloud/${languageForLinks}/marketplace/overview`;
            }}
            onClickConfirm={handleAcceptTermsAndConditions}
        />
    );
};
