import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

type LabelledInfoCellProps = PropsWithChildren<{
    labelMessageId: string;
    className?: string;
}>;

export const LabelledInfoCell = ({ labelMessageId, className, children }: LabelledInfoCellProps) => (
    <div className="flex-1-1 width-50pct">
        <label>
            <FormattedMessage id={labelMessageId} />
        </label>
        <div className={classNames('word-break', className)}>{children}</div>
    </div>
);
