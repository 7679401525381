import Select, { SelectOption } from '@rio-cloud/rio-uikit/Select';
import classNames from 'classnames';
import { useId } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeatureToggles, useFeatureToggle } from '../../../../../configuration/featureToggle/toggleHook';
import { useGetUnloadingAddressesQuery } from '../../../api/transportOrderApi';
import { AddressAndContact, OrganizationIdentifier, SelectableUnloadingAddress } from '../../transportAssignment.types';
import { ValidationResult } from './confirmationForm.types';
import { ValidatedFormGroup } from './ValidatedFormGroup';

export const UNLOADING_OPTIONS_TYPE_SEPARATOR = ' ';
export const DELETE_ID = 'delete';

const getOptionId = (identifier: OrganizationIdentifier) =>
    identifier.type + UNLOADING_OPTIONS_TYPE_SEPARATOR + identifier.id;

const mapSelectableUnloadingAddressesToOptions = (
    unloadingAddresses: SelectableUnloadingAddress[] | undefined,
    assignedAddress: AddressAndContact | undefined,
): SelectOption[] => {
    const preselectedOption = assignedAddress && {
        id: getOptionId(assignedAddress.identifier),
        label: assignedAddress.address ? assignedAddress.address.name : '',
        selected: true,
    };

    const options = unloadingAddresses?.map((address) => ({
        id: getOptionId(address.identifier),
        label: address.address.name,
        selected: preselectedOption?.id === getOptionId(address.identifier),
    }));

    options?.push({
        id: DELETE_ID,
        label: '-',
        selected: false,
    });

    return options ? options : preselectedOption ? [preselectedOption] : [];
};

type UnloadingAddressFormGroupProps = {
    transportOrderId: string;
    unloadingAddress: AddressAndContact | undefined;
    setUnloadingAddress: (
        value: SelectOption | undefined,
        selectableUnloadingAddresses: SelectableUnloadingAddress[] | undefined,
    ) => void;
    isSelectable: boolean;
    isBundle: boolean;
    validation: ValidationResult;
    isFormDisabled: boolean;
};

export const UnloadingAddressFormGroup = (props: UnloadingAddressFormGroupProps) => {
    const isTrailerIdEnabled = useFeatureToggle(FeatureToggles.TRAILER_ID, false).value;
    const id = useId();

    const intl = useIntl();
    const { data: selectableUnloadingAddresses } = useGetUnloadingAddressesQuery(
        { transportOrderId: props.transportOrderId },
        { skip: !props.isSelectable },
    );
    const options = mapSelectableUnloadingAddressesToOptions(
        selectableUnloadingAddresses,
        props.isSelectable ? props.unloadingAddress : undefined,
    );

    return (
        <div
            className={classNames('width-50pct', {
                'padding-left-5': !isTrailerIdEnabled,
                'padding-right-5': isTrailerIdEnabled,
            })}
        >
            <label
                htmlFor={id}
                className={classNames('display-flex flex-wrap justify-content-between', {
                    'height-35': !isTrailerIdEnabled,
                })}
            >
                <FormattedMessage id="outboundPortal.transportAssignments.sidebar.assignmentInformation.unloadingAddress.label" />
            </label>
            {props.isSelectable ? (
                <ValidatedFormGroup validation={props.validation}>
                    <Select
                        id={id}
                        options={options}
                        placeholder={intl.formatMessage({
                            id: 'outboundPortal.transportAssignments.sidebar.assignmentInformation.unloadingAddress.placeholder',
                        })}
                        onChange={(item) => props.setUnloadingAddress(item, selectableUnloadingAddresses)}
                        disabled={props.isFormDisabled}
                    />
                </ValidatedFormGroup>
            ) : (
                <div className="form-group">
                    <input
                        id={id}
                        className="form-control"
                        type="text"
                        disabled
                        value={
                            (props.isBundle && props.unloadingAddress === undefined
                                ? intl.formatMessage({
                                      id: 'outboundPortal.transportAssignments.sidebar.assignmentInformation.unloadingAddress.bundle',
                                  })
                                : props.unloadingAddress?.address?.name) ?? ''
                        }
                    />
                </div>
            )}
        </div>
    );
};
