import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { getServiceOrderCategory, serviceOrdersSlice } from '../../../reducers/serviceOrdersSlice';
import { ServiceOrderCategory } from '../../ServiceOrders.types';

export const ServiceOrderCategoriesSelection = () => {
    const dispatch = useAppDispatch();
    const category = useAppSelector(getServiceOrderCategory);

    const handleCategoryClick = (selectedCategory: ServiceOrderCategory) => () => {
        dispatch(serviceOrdersSlice.actions.deselectServiceOrder());
        dispatch(serviceOrdersSlice.actions.setCategory(selectedCategory));
    };

    return (
        <div className="btn-group" data-testid="toolbar-button-group">
            <Button
                active={category === ServiceOrderCategory.CURRENT}
                onClick={handleCategoryClick(ServiceOrderCategory.CURRENT)}
            >
                <span className="rioglyph rioglyph-document" />
                <FormattedMessage id="outboundPortal.serviceOrders.table.toolbar.current" />
            </Button>
            <Button
                active={category === ServiceOrderCategory.ARCHIVE}
                onClick={handleCategoryClick(ServiceOrderCategory.ARCHIVE)}
            >
                <span className="rioglyph rioglyph-folder-closed" />
                <FormattedMessage id="outboundPortal.serviceOrders.table.toolbar.archive" />
            </Button>
        </div>
    );
};
