import { ServiceOrder } from '../ServiceOrders.types';
import { useAppDispatch } from '../../../../configuration/setup/hooks';
import { getServiceOrderTableActionButtonConfig } from '../serviceOrderUtils';
import { serviceOrdersSlice } from '../../reducers/serviceOrdersSlice';
import { TableActionButton } from '../../sharedComponents/table/TableActionButton';
import { usePutArchiveServiceOrderMutation } from '../../api/serviceOrdersApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { runInBackgroundCallback } from '../../../../configuration/setup/backgroundActions';

export const ServiceOrderTableActionButton = (props: { serviceOrder: ServiceOrder }) => {
    const dispatch = useAppDispatch();
    const [archiveServiceOrder, archiveServiceOrderRequest] = usePutArchiveServiceOrderMutation();
    const actionButtonConfig = getServiceOrderTableActionButtonConfig(
        props.serviceOrder.status,
        props.serviceOrder.isFinalized,
    );

    const isLoading = archiveServiceOrderRequest.isLoading;

    const handleArchiveServiceOrder = async () => {
        await archiveServiceOrder({
            serviceOrderId: props.serviceOrder.id,
        })
            .unwrap()
            .then(() => showSuccessNotification('outboundPortal.notification.archived.success'))
            .catch(handleQueryError);
    };

    const handleOnClick = runInBackgroundCallback(async () => {
        if (actionButtonConfig.expandSidebar) {
            dispatch(serviceOrdersSlice.actions.selectServiceOrder(props.serviceOrder));
        } else {
            await handleArchiveServiceOrder();
        }
    });

    return <TableActionButton actionButtonConfig={actionButtonConfig} isLoading={isLoading} onClick={handleOnClick} />;
};
