import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const InputSign = (props: { messageId: string; glyph?: string; offsetForLabel?: boolean }) => {
    return (
        <>
            <span
                className={classNames('form-control-feedback rioglyph', props.glyph ?? 'rioglyph-exclamation-sign')}
                style={props.offsetForLabel ? { top: '27px' } : undefined}
            />
            <span className="help-block">
                <FormattedMessage id={props.messageId} />
            </span>
        </>
    );
};
