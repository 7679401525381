import { FormattedMessage } from 'react-intl';
import { ServiceOrder, ServiceOrderStatus } from '../ServiceOrders.types';
import classNames from 'classnames';
import { NoValue } from '../../sharedComponents/NoValue';
import React, { useState } from 'react';
import { UnacknowledgedChangesAlert } from '../../sharedComponents/sidebar/UnacknowledgedChangesAlert';
import { LabelWithTimezone } from '../../sharedComponents/LabelWithTimezone';
import { DateTime } from '../../sharedComponents/table/BaseTableComponents';
import { StatusLabel } from '../../sharedComponents/StatusLabel';
import { LabelledInfoCell } from '../../sharedComponents/sidebar/LabelledInfoCell';

type SidebarHeaderProps = {
    serviceOrder: ServiceOrder;
};

export const ServiceOrderSidebarHeader = (props: SidebarHeaderProps) => {
    const { serviceOrder } = props;
    const [hasChanges, setHasChanges] = useState(serviceOrder.hasUnacknowledgedExternalChanges);

    const infoClassNames = 'word-break text-size-16';

    return (
        <div>
            <div className={classNames('bg-lightest')}>
                {hasChanges && (
                    <UnacknowledgedChangesAlert
                        handleOnClick={setHasChanges}
                        alertMessage="outboundPortal.serviceOrders.alerts.unacknowledgedChanges"
                    />
                )}
                <div
                    className={classNames(
                        'border-color-light',
                        'border-style-solid',
                        'border-width-1',
                        'border-bottom-only',
                        'display-flex',
                        'gap-10',
                        'padding-20',
                    )}
                >
                    <LabelledInfoCell
                        labelMessageId="outboundPortal.serviceOrder.sidebar.serviceOrderId"
                        className="text-size-16 text-medium"
                    >
                        {serviceOrder.externalOrderId}
                    </LabelledInfoCell>
                    <LabelledInfoCell labelMessageId="outboundOrderBook.common.heading.status" className="text-size-16">
                        <StatusLabel status={serviceOrder.status} />
                    </LabelledInfoCell>
                </div>
            </div>
            <div className={classNames('display-flex', 'gap-10', 'padding-20')}>
                <div className="flex-basis-60pct">
                    <label>
                        <FormattedMessage id="outboundPortal.serviceOrder.sidebar.vehicleInformation" />
                    </label>
                    <div className={classNames(infoClassNames, 'text-medium')}>
                        {serviceOrder.vehicle.id.vin ?? serviceOrder.vehicle.id.productionNumber ?? <NoValue />}
                    </div>
                    <div className={infoClassNames}>
                        <span className="rioglyph rioglyph-car text-size-18 padding-right-5" />
                        {serviceOrder.vehicle.model?.name ?? <NoValue />}
                    </div>
                </div>
                <div className="flex-basis-40pct">
                    <LabelWithTimezone
                        messageId="outboundPortal.serviceOrder.sidebar.executionDate"
                        timeZoneData={{ timeZone: serviceOrder.timeZone, date: serviceOrder.completedAt }}
                    />
                    <div className={infoClassNames}>
                        {serviceOrder.completedAt === undefined ? (
                            <NoValue
                                messageId={
                                    serviceOrder.status === ServiceOrderStatus.CANCELLED
                                        ? undefined
                                        : 'outboundPortal.serviceOrder.sidebar.executionDate.missing'
                                }
                            />
                        ) : (
                            <DateTime dateTime={serviceOrder.completedAt} timeZone={serviceOrder.timeZone} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
