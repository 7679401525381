import Button from '@rio-cloud/rio-uikit/Button';
import { FormattedMessage } from 'react-intl';
import {
    getSelectedCategory,
    getStatusFilter,
    transportAssignmentsSlice,
} from '../../../reducers/transportAssignmentsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { StatusCount, TransportAssignmentStatus } from '../../transportAssignment.types';

export enum TableCategory {
    INBOX = 'inbox',
    EN_ROUTE = 'en-route',
    FINISHED = 'finished',
    ARCHIVE = 'archive',
}

type CategoriesSelectionProps = {
    readonly assignmentsPerStatus: StatusCount | undefined;
};

const calcNumberOfItemsPerCategory = (
    activeUIStatusFilters: TransportAssignmentStatus[],
    assignmentsPerStatus: StatusCount | undefined,
): ItemsCountPerCategory => {
    if (assignmentsPerStatus === undefined)
        return {
            [TableCategory.INBOX]: 0,
            [TableCategory.FINISHED]: 0,
            [TableCategory.EN_ROUTE]: 0,
        };

    if (activeUIStatusFilters.length > 0) {
        const uiStatusToStatusCountProp = (uiStatus: TransportAssignmentStatus): number => {
            switch (uiStatus) {
                case TransportAssignmentStatus.UNCONFIRMED:
                    return assignmentsPerStatus.unconfirmed;
                case TransportAssignmentStatus.COMPLETE_CONFIRMED:
                case TransportAssignmentStatus.INCOMPLETE_CONFIRMED:
                    return assignmentsPerStatus.confirmed;
                case TransportAssignmentStatus.CANCELLATION:
                    return assignmentsPerStatus.cancellation;
                default:
                    return 0;
            }
        };
        return {
            [TableCategory.INBOX]: activeUIStatusFilters
                .map(uiStatusToStatusCountProp)
                .reduce((acc, curr) => acc + curr),
            [TableCategory.FINISHED]: 0,
            [TableCategory.EN_ROUTE]: 0,
        };
    }

    return {
        [TableCategory.INBOX]:
            assignmentsPerStatus.cancellation + assignmentsPerStatus.confirmed + assignmentsPerStatus.unconfirmed,
        [TableCategory.FINISHED]: assignmentsPerStatus.unloaded,
        [TableCategory.EN_ROUTE]: assignmentsPerStatus.loaded,
    };
};

export type ItemsCountPerCategory = { [key in Exclude<TableCategory, TableCategory.ARCHIVE>]: number };

const ItemsBadge = (props: { count: number }) =>
    props.count > 0 ? <span className="badge badge-primary margin-left-5">{props.count}</span> : <></>;

export const CategoriesSelection = (props: CategoriesSelectionProps) => {
    const dispatch = useAppDispatch();
    const { assignmentsPerStatus } = props;
    const activeCategory = useAppSelector(getSelectedCategory);
    const activeUIStatusFilters = useAppSelector(getStatusFilter);

    const itemsInCategory = calcNumberOfItemsPerCategory(activeUIStatusFilters, assignmentsPerStatus);

    const handleCategoryClick = (selectedCategory: TableCategory) => () => {
        dispatch(transportAssignmentsSlice.actions.deselectTransportAssignment());
        dispatch(transportAssignmentsSlice.actions.setStatusFilter([]));
        dispatch(transportAssignmentsSlice.actions.setCategory(selectedCategory));
    };

    return (
        <div className="btn-group" data-testid="toolbar-button-group">
            <Button active={activeCategory === TableCategory.INBOX} onClick={handleCategoryClick(TableCategory.INBOX)}>
                <span className="rioglyph rioglyph-arrow-right" />
                <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.inbox" />
                <ItemsBadge count={itemsInCategory[TableCategory.INBOX]} />
            </Button>
            <Button
                active={activeCategory === TableCategory.EN_ROUTE}
                onClick={handleCategoryClick(TableCategory.EN_ROUTE)}
            >
                <span className="rioglyph rioglyph-truck" />
                <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.enRoute" />
                <ItemsBadge count={itemsInCategory[TableCategory.EN_ROUTE]} />
            </Button>
            <Button
                active={activeCategory === TableCategory.FINISHED}
                onClick={handleCategoryClick(TableCategory.FINISHED)}
            >
                <span className="rioglyph rioglyph-finish" />
                <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.finished" />
                <ItemsBadge count={itemsInCategory[TableCategory.FINISHED]} />
            </Button>
            <Button
                active={activeCategory === TableCategory.ARCHIVE}
                onClick={handleCategoryClick(TableCategory.ARCHIVE)}
            >
                <span className="rioglyph rioglyph-folder-closed" />
                <FormattedMessage id="outboundPortal.transportAssignments.table.toolbar.archived" />
            </Button>
        </div>
    );
};
