import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../configuration/setup/hooks';
import { compoundManagementSlice, getDepartedVehiclesSorting } from '../../../reducers/compoundManagementSlice';
import { AppTableCardsSorting } from '../../../sharedComponents/table/AppTableCardsSorting';
import { TableSort } from '../../../sharedComponents/table/SortedTableHeader';
import { DepartedVehicle } from '../../compoundManagement.types';
import { Table } from '../../shared/table/Table';
import { CompoundManagementTableRow } from '../../table/CompoundManagementTableRow';
import { departedVehiclesColumnDefinition, DepartedVehiclesTableColumn } from './DepartedVehiclesTableColumn';
import { DepartedVehicleTableDataContent } from './DepartedVehicleTableDataContent';

type DepartedVehiclesTableProps = {
    readonly vehicles: readonly DepartedVehicle[];
};
export const DepartedVehiclesTable: FunctionComponent<DepartedVehiclesTableProps> = ({ vehicles }) => {
    const sorting = useAppSelector(getDepartedVehiclesSorting);
    const dispatch = useAppDispatch();

    const setSorting = (newSorting: TableSort<DepartedVehiclesTableColumn>) =>
        dispatch(compoundManagementSlice.actions.setDepartedSorting(newSorting));

    return (
        <>
            <AppTableCardsSorting
                columnDefinition={departedVehiclesColumnDefinition}
                sorting={sorting}
                setSorting={setSorting}
            />
            <Table
                columnDefinition={departedVehiclesColumnDefinition}
                sorting={sorting}
                setSorting={setSorting}
                items={vehicles}
                notFoundMessage={
                    <FormattedMessage id="outboundOrderBook.compoundManagement.departedVehicles.notFound" />
                }
            >
                {vehicles.map((vehicle: DepartedVehicle) => (
                    <CompoundManagementTableRow
                        key={vehicle.id}
                        highlight={vehicle.arrival.isCancelled ? 'warning' : undefined}
                        columnDefinition={departedVehiclesColumnDefinition}
                        vehicle={vehicle}
                    >
                        {(column) => <DepartedVehicleTableDataContent column={column} vehicle={vehicle} />}
                    </CompoundManagementTableRow>
                ))}
            </Table>
        </>
    );
};
