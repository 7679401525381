import { FunctionComponent } from 'react';
import { useAppSelector } from '../../../configuration/setup/hooks';
import { getActiveCompoundManagementView } from '../reducers/compoundManagementSlice';
import { DepartedVehicles } from './departed/DepartedVehicles';
import { VehicleInventory } from './inventory/VehicleInventory';
import { CompoundManagementView } from './compoundManagement.types';
import { IncomingVehicles } from './incoming/IncomingVehicles';
import { OutgoingVehicles } from './outgoing/OutgoingVehicles';
import { assertExhaustive } from '../../../utils';

export const CompoundManagement: FunctionComponent = () => {
    const activeView = useAppSelector(getActiveCompoundManagementView);
    switch (activeView) {
        case CompoundManagementView.INCOMING_VEHICLES:
            return <IncomingVehicles />;
        case CompoundManagementView.INVENTORY:
            return <VehicleInventory />;
        case CompoundManagementView.OUTGOING_VEHICLES:
            return <OutgoingVehicles />;
        case CompoundManagementView.DEPARTED:
            return <DepartedVehicles />;
        default:
            assertExhaustive(activeView);
    }
};
