import { TransportAssignment } from '../transportAssignment.types';
import { FormattedMessage } from 'react-intl';
import { TransportAssignmentRow } from './TransportAssignmentsTableRow';
import { transportAssignmentColumnDefinition, TransportAssignmentTableColumn } from './TransportAssignmentTableColumns';
import { useAppSelector } from '../../../../configuration/setup/hooks';
import { getSelectedCategory } from '../../reducers/transportAssignmentsSlice';
import { TableCategory } from './toolbar/CategoriesSelection';
import { getTableViewType } from '../../reducers/viewSlice';
import { useGetUserTriggeredFetching } from '../../sharedComponents/userTriggeredFetchingHook';
import { ContentLoadingRow } from '../../sharedComponents/table/BaseTableComponents';
import { getTableClassNames } from '../../sharedComponents/table/SortedTableHeader';
import NotFoundState from '@rio-cloud/rio-uikit/NotFoundState';
import { TransportAssignmentTableHeader } from './TransportAssignmentTableHeader';

type TransportAssignmentTableProps = {
    readonly transportAssignments: readonly TransportAssignment[];
};

export const TransportAssignmentsTable = ({ transportAssignments }: TransportAssignmentTableProps) => {
    const activeTableCategory = useAppSelector(getSelectedCategory);
    const isFetchingQueryTriggerByUser = useGetUserTriggeredFetching();
    const viewType = useAppSelector(getTableViewType);

    const tableClassNames = getTableClassNames(transportAssignments, viewType);

    const isArchiveTab = activeTableCategory === TableCategory.ARCHIVE;

    const colsToDisplay = transportAssignmentColumnDefinition.filter(
        (def) => !isArchiveTab || def.column !== TransportAssignmentTableColumn.ACTION,
    );

    return (
        <table className={tableClassNames}>
            <colgroup>
                {colsToDisplay.map((def) =>
                    def.column === TransportAssignmentTableColumn.HAS_CHANGES ? (
                        <col key={def.column} className="width-40" />
                    ) : (
                        <col key={def.column} />
                    ),
                )}
            </colgroup>
            <TransportAssignmentTableHeader />
            <tbody>
                {isFetchingQueryTriggerByUser ? (
                    <ContentLoadingRow numberOfColumns={colsToDisplay.length} />
                ) : transportAssignments.length <= 0 ? (
                    <tr>
                        <td colSpan={colsToDisplay.length}>
                            <NotFoundState
                                headline={<FormattedMessage id="outboundPortal.transportAssignments.notFound" />}
                                outerClassName="border-none"
                            />
                        </td>
                    </tr>
                ) : (
                    transportAssignments.map((transportAssignment: TransportAssignment) => (
                        <TransportAssignmentRow
                            key={transportAssignment.id}
                            transportAssignment={transportAssignment}
                            isArchivedTab={isArchiveTab}
                        />
                    ))
                )}
            </tbody>
        </table>
    );
};
