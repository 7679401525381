import { FormattedMessage } from 'react-intl';
import { NoValue } from '../../../sharedComponents/NoValue';
import { Vehicle } from '../../compoundManagement.types';

export const DisplayFuelType = ({ vehicle }: { vehicle: Vehicle }) => {
    const fuelType = vehicle.model.fuelType;
    return fuelType === undefined ? (
        <NoValue />
    ) : (
        <div className="ellipsis-1">
            <FormattedMessage id={`outboundOrderBook.common.fuelType.${fuelType}`} />
        </div>
    );
};
