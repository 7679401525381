import { FormattedMessage } from 'react-intl';
import { NoValue } from '../../../sharedComponents/NoValue';
import { Vehicle } from '../../compoundManagement.types';

export const DisplayDispatchType = ({ vehicle }: { vehicle: Vehicle }) => {
    return vehicle.vehicleType === undefined ? (
        <NoValue />
    ) : (
        <div className="ellipsis-1">
            <FormattedMessage id={`outboundOrderBook.common.vehicleType.${vehicle.vehicleType}`} />
        </div>
    );
};
