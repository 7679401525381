import { formatDateTimeInTimeZone } from '../dateHelper';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { AlertConfig } from '../common.types';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';

export const TABLE_CONTENT_LOADER_TEST_ID = 'TABLE_CONTENT_LOADER_TEST_ID';

export const DateTime = (props: { dateTime: Date; timeZone: string }) => (
    <span>{formatDateTimeInTimeZone(props.dateTime, props.timeZone)}</span>
);

export const AlertIcon = (props: { rowConfig: AlertConfig[] }) => {
    if (props.rowConfig.length === 0) {
        return <></>;
    } else {
        return (
            <>
                {props.rowConfig.map((config, index) => (
                    <div key={index}>
                        <SimpleTooltip content={<FormattedMessage id={config.hintMessageKey} />} placement="auto">
                            <span
                                className={classNames('rioglyph', 'text-size-20', config.rowIcon)}
                                aria-hidden="true"
                            />
                        </SimpleTooltip>
                    </div>
                ))}
            </>
        );
    }
};

export const ContentLoadingRow = (props: { numberOfColumns: number }) => {
    return (
        <tr>
            {[...Array(props.numberOfColumns)].map((_, idx) => (
                <td key={idx}>
                    <ContentLoader data-testid={TABLE_CONTENT_LOADER_TEST_ID} />
                </td>
            ))}
        </tr>
    );
};
