import { FunctionComponent } from 'react';
import { LabelledInfoCell } from '../../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, Departure } from '../../compoundManagement.types';
import { DateTimeInfo } from '../../shared/dataDisplay/DateTimeInfo';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';

export type DepartureDetailsProps = {
    departure: Departure;
    compoundLocation: CompoundLocation;
};

export const DepartureDetails: FunctionComponent<DepartureDetailsProps> = ({ departure, compoundLocation }) => {
    const rowClasses = 'display-flex gap-25 align-items-start';
    const departureCompletedAt = departure.departureCompletedAt;

    return (
        <div className={rowClasses}>
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.departureDate">
                <DateTimeInfo date={departureCompletedAt} timezone={compoundLocation?.timeZone} />
            </LabelledInfoCell>
            <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.carrier">
                <DisplayFreightForwarder freightForwarder={departure.freightForwarder} />
            </LabelledInfoCell>
        </div>
    );
};
