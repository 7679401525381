/**
 * Filters an array of tuples of the form [boolean, T] and returns the first T for which the boolean was true.
 *
 * @example
 * getFirstMatchingCondition([false, "first"], [true && true, "second"]); // === "second"
 */
export const getFirstMatchingCondition = <T extends unknown>(...inputs: [boolean, T][]): T | undefined =>
    inputs.filter(([condition]) => condition).map(([, value]) => value)[0];

/**
 * helper function for compile time exhaustiveness checks e.g. in switch statements.
 */
export const assertExhaustive = (value: never) => {
    throw new Error(`exhaustiveness check failed for value: ${value}`);
};
