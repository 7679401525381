import Button from '@rio-cloud/rio-uikit/Button';
import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../../../configuration/setup/hooks';
import { showSuccessNotification } from '../../../notifications/SuccessNotification';
import { compoundManagementSlice } from '../../../reducers/compoundManagementSlice';
import { NoValue } from '../../../sharedComponents/NoValue';
import { LabelledInfoCell } from '../../../sharedComponents/sidebar/LabelledInfoCell';
import { CompoundLocation, ExpectedDeparture } from '../../compoundManagement.types';
import { DateTimeInfo } from '../../shared/dataDisplay/DateTimeInfo';
import { DisplayCancellableData } from '../../shared/dataDisplay/DisplayCancellableData';
import { DisplayFreightForwarder } from '../../shared/dataDisplay/DisplayFreightForwarder';
import { DisplayMeansOfTransportType } from '../../shared/dataDisplay/DisplayMeansOfTransportType';
import { CompletedStatusAtInput } from '../../shared/sidebar/CompletedStatusAtInput';
import { getStatusDateValidationMessageId } from '../../shared/sidebar/validateStatusDate';
import { usePutDepartureCompletionMutation } from '../../../api/compoundManagementApi';
import { runInBackgroundCallback } from '../../../../../configuration/setup/backgroundActions';
import { handleQueryError } from '../../../notifications/ErrorNotification';

export type ExpectedDepartureDetailsProps = {
    compoundVehicleId: string;
    departure: ExpectedDeparture;
    compoundLocation: CompoundLocation;
};

export const ExpectedDepartureDetails: FunctionComponent<ExpectedDepartureDetailsProps> = ({
    compoundVehicleId,
    departure,
    compoundLocation,
}) => {
    const dispatch = useAppDispatch();
    const [completeDeparture, completeDepartureRequest] = usePutDepartureCompletionMutation();

    const rowClasses = 'display-flex gap-25 align-items-start';
    const expectedDepartureAt = departure.expectedDepartureAt;
    const isCancelled = departure.isCancelled;

    const [submitted, setSubmitted] = useState(false);
    const [departureCompletedAt, setDepartureCompletedAt] = useState<Date | string | undefined>(undefined);

    const handleConfirmDeparture = runInBackgroundCallback(async () => {
        setSubmitted(true);

        const isStatusValid = getStatusDateValidationMessageId(departureCompletedAt) === undefined;

        if (isStatusValid) {
            try {
                await completeDeparture({
                    compoundVehicleId,
                    departedStatusChange: { statusAt: departureCompletedAt as Date },
                }).unwrap();

                showSuccessNotification(
                    'outboundOrderBook.compoundManagement.notification.outgoingVehicle.confirmDeparture.success',
                );
                dispatch(compoundManagementSlice.actions.deselectCompoundVehicle());
            } catch (error: unknown) {
                handleQueryError(error);
            }
        }
    });

    return (
        <>
            <div className={classNames(rowClasses, 'margin-bottom-15')}>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.table.heading.expectedDepartureDate">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DateTimeInfo date={expectedDepartureAt} timezone={compoundLocation?.timeZone} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.carrierAndTransportType">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        <DisplayFreightForwarder freightForwarder={departure.freightForwarder} />
                        {' / '}
                        <DisplayMeansOfTransportType meansOfTransportType={departure.meansOfTransport.type} />
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </div>
            <div className={rowClasses}>
                <CompletedStatusAtInput
                    labelId="outboundOrderBook.compoundManagement.table.heading.departureDate"
                    timeZone={compoundLocation.timeZone}
                    disabled={isCancelled}
                    statusDate={departureCompletedAt}
                    onChangeStatusDate={setDepartureCompletedAt}
                    validationErrorId={submitted ? getStatusDateValidationMessageId(departureCompletedAt) : undefined}
                />

                <LabelledInfoCell labelMessageId="outboundOrderBook.compoundManagement.sidebar.details.meansOfTransport">
                    <DisplayCancellableData isCancelled={isCancelled}>
                        {departure.meansOfTransport.id ?? <NoValue />}
                    </DisplayCancellableData>
                </LabelledInfoCell>
            </div>
            {!isCancelled && (
                <>
                    <hr />
                    <div className="display-flex gap-25 justify-content-end">
                        <ConfirmButton
                            isLoading={completeDepartureRequest.isLoading}
                            onClick={handleConfirmDeparture}
                        />
                    </div>
                </>
            )}
        </>
    );
};

const ConfirmButton: FunctionComponent<{ isLoading: boolean; onClick: () => void }> = ({ isLoading, onClick }) => (
    <Button
        className={classNames(isLoading ? 'btn-loading' : '')}
        disabled={isLoading}
        iconName="rioglyph-load-unload"
        bsStyle={Button.PRIMARY}
        onClick={onClick}
    >
        <FormattedMessage id="outboundPortal.compoundManagement.button.confirmDeparture" />
    </Button>
);
