import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import Dialog from '@rio-cloud/rio-uikit/Dialog';
import { FormattedMessage } from 'react-intl';
import { useDeleteServiceProviderMutation } from '../api/serviceProviderApi';
import { handleQueryError } from '../notifications/ErrorNotification';
import { showSuccessNotification } from '../notifications/SuccessNotification';
import { ConfirmButtonText } from '../sharedComponents/ConfirmButtonText';
import { runInBackgroundCallback } from '../../../configuration/setup/backgroundActions';

export const ActivationRequestRejection = ({
    serviceProviderName,
    serviceProviderAccountId,
    show,
    setShow,
}: {
    serviceProviderName: string;
    serviceProviderAccountId: string;
    show: boolean;
    setShow: (value: boolean) => void;
}) => {
    const [rejectServiceProvider, rejectionRequest] = useDeleteServiceProviderMutation();

    const handleOnClick = runInBackgroundCallback(async () => {
        setShow(false);
        await rejectServiceProvider({
            accountId: serviceProviderAccountId,
        })
            .unwrap()
            .then(() =>
                showSuccessNotification('outboundOrderBook.serviceProviders.activationRequestRejection.success'),
            )
            .catch(handleQueryError);
    });

    return (
        <ConfirmationDialog
            show={show}
            title={<FormattedMessage id="outboundOrderBook.serviceProviders.activationRequestRejection.title" />}
            content={
                <FormattedMessage
                    id="outboundOrderBook.serviceProviders.activationRequestRejection.text"
                    values={{ serviceProvider: <strong>{serviceProviderName}</strong> }}
                />
            }
            bsSize={Dialog.SIZE_SM}
            onClickConfirm={handleOnClick}
            onClickCancel={() => setShow(false)}
            cancelButtonText={<FormattedMessage id="outboundOrderBook.common.button.cancel" />}
            confirmButtonText={
                <ConfirmButtonText
                    labelId="outboundOrderBook.common.button.reject"
                    showSpinner={rejectionRequest.isLoading}
                />
            }
            useOverflow
        />
    );
};
