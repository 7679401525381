import isEmpty from 'lodash/isEmpty';
import { parseOptionalStringDate, parseStringDate } from '../../../sharedComponents/dateHelper';
import {
    AddressAndContact,
    Contact,
    ExecutionEvent,
    ExecutionEventType,
    ExecutionStatus,
    Loading,
    MeansOfTransport,
    MeansOfTransportType,
    ModeOfTransport,
    OrganizationIdentifier,
    OrganizationIdentifierType,
    OriginatedTransportOrder,
    ReportedIncident,
    SelectableUnloadingAddress,
    StatusCount,
    TransportAssignment,
    TransportAssignmentPage,
    TransportAssignmentStatus,
    TransportAssignmentType,
    TransportCapacityOrder,
    TransportCapacityOrderAddressAndContact,
    TransportCapacityOrderUnloading,
    TransportOrder,
    TransportOrderBundle,
    TransportOrderBundleDeliveries,
    Unloading,
} from '../../../transportAssignment/transportAssignment.types';
import {
    ApiAddressAndContact,
    ApiContact,
    ApiSelectableUnloadingAddresses,
    ApiTransportCapacityOrderAddressAndContact,
} from '../../types/transportAssignment/addressAndContactApi.types';
import {
    ApiOrganizationIdentifier,
    ApiOrganizationIdentifierType,
} from '../../types/transportAssignment/sharedTypesApi.types';
import {
    ApiMeansOfTransport,
    ApiMeansOfTransportType,
    ApiModeOfTransport,
} from '../../types/transportAssignment/transportApi.types';
import {
    ApiDateTimeInterval,
    ApiExecutionEvent,
    ApiExecutionEventType,
    ApiExecutionStatus,
    ApiLoading,
    ApiOriginatedTransportOrder,
    ApiReportedIncident,
    ApiStatus,
    ApiStatusCount,
    ApiTransportAssignment,
    ApiTransportAssignmentPage,
    ApiTransportAssignmentType,
    ApiTransportCapacityOrder,
    ApiTransportCapacityOrderUnloading,
    ApiTransportOrder,
    ApiTransportOrderBundle,
    ApiUnloading,
} from '../../types/transportAssignment/transportAssignmentsApi.types';
import { mapToAddress } from '../addressMappers';
import { mapToTimeZone } from '../timeZoneMappers';
import { mapToVehicle, mapToVehicleConditionType } from '../vehicleMappers';
import { Interval } from 'date-fns';
import { getBundleUnloadingConfirmedDeliveryDate } from '../../../transportAssignment/TransportAssignmentUtils';

export const mapToReportedIncident = (apiReportedIncident: ApiReportedIncident): ReportedIncident => {
    return {
        type: mapToVehicleConditionType(apiReportedIncident.type),
        isEmptyRun: apiReportedIncident.is_empty_run,
        isActive: apiReportedIncident.is_active,
        changedAt: parseStringDate(apiReportedIncident.changed_at),
        description: apiReportedIncident.description,
    };
};

export const mapDateInterval = (apiDateInterval: ApiDateTimeInterval): Interval => {
    return {
        start: parseStringDate(apiDateInterval.start_at),
        end: parseStringDate(apiDateInterval.end_at),
    };
};

export const mapToOptionalModeOfTransport = (
    apiModeOfTransport: ApiModeOfTransport | undefined,
): ModeOfTransport | undefined => {
    switch (apiModeOfTransport) {
        case undefined:
            return undefined;
        case ApiModeOfTransport.ROAD_TRANSPORT:
            return ModeOfTransport.ROAD_TRANSPORT;
    }
};

export const mapToMeansOfTransportType = (apiMeansOfTransportType: ApiMeansOfTransportType): MeansOfTransportType => {
    switch (apiMeansOfTransportType) {
        case ApiMeansOfTransportType.TRUCK:
            return MeansOfTransportType.TRUCK;
    }
};

export const mapToMeansOfTransport = (apiMeansOfTransport: ApiMeansOfTransport): MeansOfTransport => {
    return {
        id: apiMeansOfTransport.id,
        assetId: apiMeansOfTransport.asset_id,
        trailerId: apiMeansOfTransport.trailer_id,
        type: mapToMeansOfTransportType(apiMeansOfTransport.type),
    };
};

export const mapToStatus = (apiStatus: ApiStatus, isCompleteConfirmed: boolean): TransportAssignmentStatus => {
    switch (apiStatus) {
        case ApiStatus.CANCELLATION:
            return TransportAssignmentStatus.CANCELLATION;
        case ApiStatus.CONFIRMED:
            return isCompleteConfirmed
                ? TransportAssignmentStatus.COMPLETE_CONFIRMED
                : TransportAssignmentStatus.INCOMPLETE_CONFIRMED;
        case ApiStatus.UNCONFIRMED:
            return TransportAssignmentStatus.UNCONFIRMED;
        case ApiStatus.LOADED:
            return TransportAssignmentStatus.LOADED;
        case ApiStatus.UNLOADED:
            return TransportAssignmentStatus.UNLOADED;
    }
};

export const mapToExecutionStatus = (eventStatus: ApiExecutionStatus): ExecutionStatus => {
    switch (eventStatus) {
        case ApiExecutionStatus.LOADED:
            return ExecutionStatus.LOADED;
        case ApiExecutionStatus.UNLOADED:
            return ExecutionStatus.UNLOADED;
    }
};

export const mapToExecutionEventType = (
    eventType: ApiExecutionEventType | undefined,
): ExecutionEventType | undefined => {
    switch (eventType) {
        case ApiExecutionEventType.AUTOMATIC:
            return ExecutionEventType.AUTOMATIC;
        case ApiExecutionEventType.NON_AUTOMATIC:
            return ExecutionEventType.NON_AUTOMATIC;
        case undefined:
            return undefined;
    }
};

export const mapToExecutionEvents = (apiExecutionEvents?: ApiExecutionEvent[]): ExecutionEvent[] =>
    apiExecutionEvents?.map(
        (apiExecutionEvent: ApiExecutionEvent) =>
            ({
                eventStatus: mapToExecutionStatus(apiExecutionEvent.event_status),
                eventAt: parseStringDate(apiExecutionEvent.event_at),
                eventType: mapToExecutionEventType(apiExecutionEvent.event_type),
            }) as ExecutionEvent,
    ) ?? [];

export const mapToOrganizationIdentifierType = (
    apiOrganizationIdentifierType: ApiOrganizationIdentifierType,
): OrganizationIdentifierType => {
    switch (apiOrganizationIdentifierType) {
        case ApiOrganizationIdentifierType.BUYER:
            return OrganizationIdentifierType.BUYER;
    }
};

export const mapToOrganizationIdentifier = (
    apiOrganizationIdentifier: ApiOrganizationIdentifier,
): OrganizationIdentifier => ({
    id: apiOrganizationIdentifier.id,
    type: mapToOrganizationIdentifierType(apiOrganizationIdentifier.type),
});

export const mapToSelectableUnloadingAddresses = (
    addresses: ApiSelectableUnloadingAddresses,
): SelectableUnloadingAddress[] =>
    addresses.items.map((it) => ({
        address: mapToAddress(it.address),
        identifier: mapToOrganizationIdentifier(it.identifier),
    }));

export const mapToContact = (apiContact: ApiContact): Contact => {
    return {
        name: apiContact.name,
        email: apiContact.email,
        phone: apiContact.phone,
    };
};

export const mapToAddressAndContact = (apiAddressAndContact: ApiAddressAndContact): AddressAndContact => {
    return {
        identifier: mapToOrganizationIdentifier(apiAddressAndContact.identifier),
        address: apiAddressAndContact.address && mapToAddress(apiAddressAndContact.address),
        contact: apiAddressAndContact.contact && mapToContact(apiAddressAndContact.contact),
    };
};

export const mapToTransportCapacityOrderAddressAndContact = (
    apiAddressAndContact: ApiTransportCapacityOrderAddressAndContact,
): TransportCapacityOrderAddressAndContact => {
    return {
        identifier: mapToOrganizationIdentifier(apiAddressAndContact.identifier),
        address: mapToAddress(apiAddressAndContact.address),
        contact: apiAddressAndContact.contact && mapToContact(apiAddressAndContact.contact),
    };
};

export const mapToLoading = (apiLoading: ApiLoading): Loading => {
    return {
        addressAndContact: mapToAddressAndContact(apiLoading.address_and_contact),
        requestedPickUpDate: mapDateInterval(apiLoading.requested_pick_up_at),
        confirmedPickUpDate: parseOptionalStringDate(apiLoading.confirmed_pick_up_at),
        timeZone: mapToTimeZone(apiLoading.time_zone),
    };
};

export const mapToUnloading = (apiUnloading: ApiUnloading): Unloading => {
    return {
        addressAndContact: apiUnloading.address_and_contact && mapToAddressAndContact(apiUnloading.address_and_contact),
        requestedDeliveryDate: parseStringDate(apiUnloading.requested_delivery_at),
        confirmedDeliveryDate: parseOptionalStringDate(apiUnloading.confirmed_delivery_at),
        timeZone: mapToTimeZone(apiUnloading.time_zone),
    };
};

export const mapToTransportCapacityOrderUnloading = (
    apiUnloading: ApiTransportCapacityOrderUnloading,
): TransportCapacityOrderUnloading => {
    return {
        addressAndContact: mapToTransportCapacityOrderAddressAndContact(apiUnloading.address_and_contact),
        requestedDeliveryDate: parseOptionalStringDate(apiUnloading.requested_delivery_at),
        confirmedDeliveryDate: parseOptionalStringDate(apiUnloading.confirmed_delivery_at),
        timeZone: mapToTimeZone(apiUnloading.time_zone),
    };
};

export const mapToOriginatedTransportOrder = (
    apiOriginatedTransportOrder: ApiOriginatedTransportOrder,
): OriginatedTransportOrder => {
    return {
        id: apiOriginatedTransportOrder.id,
        externalOrderId: apiOriginatedTransportOrder.external_order_id,
        serviceKey: apiOriginatedTransportOrder.service_key,
        vehicle: mapToVehicle(apiOriginatedTransportOrder.vehicle),
        isCancelled: apiOriginatedTransportOrder.is_cancelled,
        isFinalized: apiOriginatedTransportOrder.is_finalized,
        isLoaded: apiOriginatedTransportOrder.is_loaded,
        executionEvents: mapToExecutionEvents(apiOriginatedTransportOrder.execution_events),
        generalInformation: apiOriginatedTransportOrder.general_information,
        incident: apiOriginatedTransportOrder.incident && mapToReportedIncident(apiOriginatedTransportOrder.incident),
    };
};

export const mapToStatusCount = (statusCount: ApiStatusCount): StatusCount => ({
    cancellation: statusCount.cancellation,
    unloaded: statusCount.unloaded,
    loaded: statusCount.loaded,
    confirmed: statusCount.confirmed,
    unconfirmed: statusCount.unconfirmed,
});

export const isTransportAssignmentConfirmationComplete = (
    apiTransportAssignment: ApiTransportOrder | ApiTransportCapacityOrder,
): boolean =>
    !isEmpty(apiTransportAssignment.means_of_transport?.id) &&
    !isEmpty(apiTransportAssignment.loading.confirmed_pick_up_at) &&
    !isEmpty(apiTransportAssignment.unloading.confirmed_delivery_at) &&
    apiTransportAssignment.unloading.address_and_contact !== undefined;

export const isBundleConfirmationComplete = (
    apiTransportOrderBundle: ApiTransportOrderBundle,
    unloadingList: Unloading[],
): boolean =>
    !isEmpty(apiTransportOrderBundle.means_of_transport?.id) &&
    !isEmpty(apiTransportOrderBundle.loading.confirmed_pick_up_at) &&
    getBundleUnloadingConfirmedDeliveryDate(unloadingList) !== undefined;

const mapSingleTransportOrder = (transportOrder: ApiTransportOrder): TransportOrder => ({
    id: transportOrder.id,
    type: TransportAssignmentType.TRANSPORT_ORDER,
    externalOrderId: transportOrder.external_order_id,
    serviceKey: transportOrder.service_key,
    status: mapToStatus(transportOrder.status, isTransportAssignmentConfirmationComplete(transportOrder)),
    executionEvents: mapToExecutionEvents(transportOrder.execution_events),
    vehicle: mapToVehicle(transportOrder.vehicle),
    loading: mapToLoading(transportOrder.loading),
    unloading: mapToUnloading(transportOrder.unloading),
    meansOfTransport: transportOrder.means_of_transport && mapToMeansOfTransport(transportOrder.means_of_transport),
    modeOfTransport: mapToOptionalModeOfTransport(transportOrder.mode_of_transport),
    generalInformation: transportOrder.general_information,
    receivedAt: parseStringDate(transportOrder.received_at),
    hasUnacknowledgedExternalChanges: transportOrder.has_unacknowledged_external_changes,
    hasSelectableUnloadingAddress: transportOrder.has_selectable_unloading_address,
    isArchived: transportOrder.is_archived,
    isFinalized: transportOrder.is_finalized,
    isAutomaticallyTracked: transportOrder.is_qualified_for_automatic_tracking,
    incident: transportOrder.incident && mapToReportedIncident(transportOrder.incident),
});

const mapTransportOrderBundle = (bundle: ApiTransportOrderBundle): TransportOrderBundle => {
    // TODO: The status for a bundle is quite inconsistent, mainly for the "incomplete confirmation",
    //  that is not also necessary for OriginatedTransportOrder but totally ignored.
    //  Depending how the FE will look like, the "incomplete" could be a general flag, that can also be
    //  used while on other statuses (e.g. Loaded)
    const deliveries: TransportOrderBundleDeliveries[] = bundle.deliveries.map((apiDelivery) => {
        const unloading = mapToUnloading(apiDelivery.unloading);
        return {
            unloading,
            originatedTransportOrders: apiDelivery.originated_transport_orders.map((apiOriginatedTransportOrder) =>
                mapToOriginatedTransportOrder(apiOriginatedTransportOrder),
            ),
        };
    });
    return {
        id: bundle.id,
        type: TransportAssignmentType.TRANSPORT_ORDER_BUNDLE,
        status: mapToStatus(
            bundle.status,
            isBundleConfirmationComplete(
                bundle,
                deliveries.map((delivery) => delivery.unloading),
            ),
        ),
        numberOfVehicles: bundle.number_of_vehicles,
        modelGroups: bundle.model_groups,
        loading: mapToLoading(bundle.loading),
        deliveries,
        meansOfTransport: bundle.means_of_transport && mapToMeansOfTransport(bundle.means_of_transport),
        modeOfTransport: mapToOptionalModeOfTransport(bundle.mode_of_transport),
        receivedAt: parseStringDate(bundle.received_at),
        hasUnacknowledgedExternalChanges: bundle.has_unacknowledged_external_changes,
        isArchived: bundle.is_archived,
        isFinalized: bundle.is_finalized,
        isAutomaticallyTracked: bundle.is_qualified_for_automatic_tracking,
    };
};

const mapTransportCapacityOrder = (transportCapacityOrder: ApiTransportCapacityOrder): TransportCapacityOrder => ({
    id: transportCapacityOrder.id,
    type: TransportAssignmentType.TRANSPORT_CAPACITY_ORDER,
    bundleId: transportCapacityOrder.bundle_id,
    status: mapToStatus(
        transportCapacityOrder.status,
        isTransportAssignmentConfirmationComplete(transportCapacityOrder),
    ),
    loading: mapToLoading(transportCapacityOrder.loading),
    unloading: mapToTransportCapacityOrderUnloading(transportCapacityOrder.unloading),
    meansOfTransport:
        transportCapacityOrder.means_of_transport && mapToMeansOfTransport(transportCapacityOrder.means_of_transport),
    modeOfTransport: mapToOptionalModeOfTransport(transportCapacityOrder.mode_of_transport),
    requestedTransportCapacity: transportCapacityOrder.requested_transport_capacity,
    generalInformation: transportCapacityOrder.general_information,
    receivedAt: parseStringDate(transportCapacityOrder.received_at),
    hasUnacknowledgedExternalChanges: transportCapacityOrder.has_unacknowledged_external_changes,
    isAutomaticallyTracked: transportCapacityOrder.is_qualified_for_automatic_tracking,
    isArchived: transportCapacityOrder.is_archived,
    isFinalized: transportCapacityOrder.is_finalized,
});

export const mapTransportAssignmentPage = (response: ApiTransportAssignmentPage): TransportAssignmentPage => ({
    items: response.items.map((apiTransportAssignment: ApiTransportAssignment): TransportAssignment => {
        switch (apiTransportAssignment.type) {
            case ApiTransportAssignmentType.TRANSPORT_ORDER:
                return mapSingleTransportOrder(apiTransportAssignment as ApiTransportOrder);
            case ApiTransportAssignmentType.TRANSPORT_ORDER_BUNDLE:
                return mapTransportOrderBundle(apiTransportAssignment as ApiTransportOrderBundle);
            case ApiTransportAssignmentType.TRANSPORT_CAPACITY_ORDER:
                return mapTransportCapacityOrder(apiTransportAssignment as ApiTransportCapacityOrder);
        }
    }),
    hasMore: response.has_more,
    statusCount: mapToStatusCount(response.total_per_status),
});
