import { FunctionComponent } from 'react';
import { usePostTransportCapacityOrderConfirmationMutation } from '../../api/transportOrderApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../notifications/SuccessNotification';
import { getIsConfirmed } from '../TransportAssignmentUtils';
import { TransportCapacityOrder } from '../transportAssignment.types';
import { ConfirmationFormParams, TransportAssignmentConfirmationForm } from './TransportAssignmentConfirmationForm';

interface TransportCapacityOrderConfirmationFormProps {
    transportAssignment: TransportCapacityOrder;
}

export const TransportCapacityOrderConfirmationForm: FunctionComponent<TransportCapacityOrderConfirmationFormProps> = ({
    transportAssignment,
}) => {
    const [requestConfirmation, confirmationRequest] = usePostTransportCapacityOrderConfirmationMutation();

    const handleConfirmTransportCapacityOrder = async (formParams: ConfirmationFormParams): Promise<boolean> => {
        try {
            const confirmation = mapFormParamsToTransportCapacityOrderConfirmation(transportAssignment, formParams);
            await requestConfirmation(confirmation).unwrap();
            showSuccessNotification('outboundPortal.notification.confirmTransportAssignment.success');
            return true;
        } catch (error: unknown) {
            handleQueryError(error);
            return false;
        }
    };

    return (
        <TransportAssignmentConfirmationForm
            transportAssignment={transportAssignment}
            isConfirmPrimaryButton={!getIsConfirmed(transportAssignment)}
            isProcessingConfirmation={confirmationRequest.isLoading}
            handleConfirm={handleConfirmTransportCapacityOrder}
            showIncidentButton={false}
        />
    );
};

const mapFormParamsToTransportCapacityOrderConfirmation = (
    transportAssignment: TransportCapacityOrder,
    { newConfirmedPickUpDate, newConfirmedDeliveryDate, newMeansOfTransport }: ConfirmationFormParams,
) => ({
    transportCapacityOrderId: transportAssignment.id,
    confirmation: {
        changedAt: new Date(),
        pickUpAt: newConfirmedPickUpDate,
        deliverAt: newConfirmedDeliveryDate,
        meansOfTransportId: newMeansOfTransport?.id,
        trailerId: newMeansOfTransport?.trailerId,
    },
});
