import ExpanderList from '@rio-cloud/rio-uikit/ExpanderList';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Model, VehicleId } from '../../../../sharedComponents/common.types';
import { NoValue } from '../../../../sharedComponents/NoValue';
import { VehicleDetailsHeader } from '../../../../sharedComponents/sidebar/VehicleDetailsHeader';
import { TransportAssignmentsDelivery } from './shipmentDetails.types';
import { ShipmentDetailsExpanderBody } from './ShipmentDetailsExpanderBody';

type ShipmentDetailHeaderProps = {
    readonly model: Model;
    readonly vehicleId: VehicleId;
    readonly isCancelled?: boolean;
};

const ShipmentDetailHeader = ({ model, vehicleId, isCancelled = false }: ShipmentDetailHeaderProps) => {
    const strikeThroughIfCancelled = classNames({ 'text-decoration-line-through': isCancelled });
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6">
                    <div>
                        <VehicleDetailsHeader
                            messageId="outboundOrderBook.common.heading.modelOrCapacity"
                            model={model}
                            isCancelled={isCancelled}
                        />
                    </div>
                </div>
                <div className="col-sm-6 padding-left-20">
                    <label className={strikeThroughIfCancelled}>
                        {vehicleId.productionNumber !== undefined && vehicleId.vin === undefined ? (
                            <FormattedMessage id="outboundPortal.transportAssignments.sidebar.shipment.details.vehicle.productionNumber" />
                        ) : (
                            <FormattedMessage id="outboundOrderBook.common.heading.vin" />
                        )}
                    </label>
                    <div className={strikeThroughIfCancelled}>
                        {vehicleId.vin ?? vehicleId.productionNumber ?? <NoValue />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export type ShipmentDetailExpanderProps = {
    readonly deliveries: TransportAssignmentsDelivery[];
    readonly openFirst: boolean;
};

export const ShipmentDetailsExpander = ({ deliveries, openFirst }: ShipmentDetailExpanderProps) => {
    const shipmentDeliveries = deliveries.flatMap(
        ({ vehicle, serviceKey, generalInformation, isCancelled }, index) => ({
            id: vehicle.id.vin != null ? vehicle.id.vin : index.toString(),
            header: <ShipmentDetailHeader model={vehicle.model} vehicleId={vehicle.id} isCancelled={isCancelled} />,
            bodyClassName: 'border-style-solid border-width-1 border-color-light border-top-only',
            body: (
                <ShipmentDetailsExpanderBody
                    vehicle={vehicle}
                    serviceKey={serviceKey}
                    generalInformation={generalInformation}
                />
            ),
            open: openFirst && index === 0,
        }),
    );

    return <ExpanderList items={shipmentDeliveries} />;
};
