import { isValidDate } from '../../../sharedComponents/dateHelper';

export const getStatusDateValidationMessageId = (date: Date | string | undefined) => {
    if (!isValidDate(date)) {
        return 'outboundPortal.common.invalidDate';
    }

    const isInTheFuture = date > new Date();

    if (isInTheFuture) {
        return 'outboundPortal.compoundManagement.sidebar.completedDate.inTheFuture';
    }

    return undefined;
};
