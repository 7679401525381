import { FunctionComponent } from 'react';
import {
    ExecutionStatus,
    TransportAssignment,
    TransportOrder,
    TransportOrderBundle,
} from '../transportAssignment.types';
import { mapFormParamsToTransportOrderConfirmation } from './mapFormParamsToTransportOrderConfirmation';
import { ConfirmationFormParams, TransportAssignmentConfirmationForm } from './TransportAssignmentConfirmationForm';
import { getIsConfirmed, hasNewCar, hasVehiclesInStatus, mapToVehiclesInExecution } from '../TransportAssignmentUtils';
import { usePostTransportOrderConfirmationMutation } from '../../api/transportOrderApi';
import { handleQueryError } from '../../notifications/ErrorNotification';
import { showSuccessNotification } from '../../notifications/SuccessNotification';

interface TransportOrderConfirmationFormProps {
    transportAssignment: TransportOrder | TransportOrderBundle;
}

export const TransportOrderConfirmationForm: FunctionComponent<TransportOrderConfirmationFormProps> = ({
    transportAssignment,
}) => {
    const [requestConfirmation, confirmationRequest] = usePostTransportOrderConfirmationMutation();

    const handleConfirmTransportOrder = async (formParams: ConfirmationFormParams): Promise<boolean> => {
        try {
            const confirmation = mapFormParamsToTransportOrderConfirmation(transportAssignment, formParams);
            await requestConfirmation(confirmation).unwrap();
            showSuccessNotification('outboundPortal.notification.confirmTransportAssignment.success');
            return true;
        } catch (error: unknown) {
            handleQueryError(error);
            return false;
        }
    };

    return (
        <TransportAssignmentConfirmationForm
            transportAssignment={transportAssignment}
            isConfirmPrimaryButton={shouldConfirmBePrimaryButton(transportAssignment)}
            isProcessingConfirmation={confirmationRequest.isLoading}
            handleConfirm={handleConfirmTransportOrder}
            showIncidentButton={!hasNewCar(transportAssignment)}
        />
    );
};

export const shouldConfirmBePrimaryButton = (transportAssignment: TransportAssignment): boolean => {
    const allVehicles = mapToVehiclesInExecution(transportAssignment);
    const isConfirmed = getIsConfirmed(transportAssignment);

    return (
        !isConfirmed &&
        !hasVehiclesInStatus(allVehicles, ExecutionStatus.LOADED) &&
        !hasVehiclesInStatus(allVehicles, ExecutionStatus.UNLOADED)
    );
};
